import { CommonModule } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AppLinkedInPremiumPlans } from '@shared/AppEnums';
import {
  AdminLinkedInAccountDto,
  LinkedInAccountDto,
} from '@shared/service-proxies/service-proxies';

@Component({
  imports: [CommonModule, MatIconModule, MatTooltipModule],
  selector: 'linkedin-account-subscriptions',
  templateUrl: './linkedin-account-subscriptions.component.html',
  standalone: true,
})
export class LinkedInAccountSubscriptionsComponent implements OnInit {
  @Input() account: LinkedInAccountDto | AdminLinkedInAccountDto;

  @Input() iconsSize: 'small' | 'large' = 'large';
  @Input() textSize: 'small' | 'large' = 'large';
  @Input() showSubscriptionName: boolean = false;
  @Input() showFreeAccountTextIfNoSubscription: boolean = false;

  @Input() invalidNavigatorTooltip =
    'This account has a LinkedIn Sales Navigator subscription, but it has not been properly connected to the tool. You can reconnect it on the LinkedIn accounts screen.';

  @Input() invalidRecruiterTooltip =
    'This account has a LinkedIn Recruiter subscription, but it has not been properly connected to the tool. You can reconnect it on the LinkedIn accounts screen.';

  showNavigator: boolean;
  showRecruiter: boolean;
  showPremium: boolean;

  showFreeAccountText: boolean;

  ngOnInit(): void {
    this.determineShownPlans();
  }

  private determineShownPlans() {
    this.showNavigator =
      this.account?.linkedInUserProfile?.premiumPlans.includes(
        AppLinkedInPremiumPlans.SALES_NAVIGATOR,
      ) || this.account?.isValidNavigator;
    this.showRecruiter =
      this.account?.linkedInUserProfile?.premiumPlans.includes(AppLinkedInPremiumPlans.RECRUITER) ||
      this.account?.isValidRecruiter;
    this.showPremium =
      this.account?.linkedInUserProfile?.premiumPlans.includes(AppLinkedInPremiumPlans.PREMIUM) &&
      !this.showRecruiter &&
      !this.showNavigator;
    this.showFreeAccountText =
      !this.showRecruiter &&
      !this.showNavigator &&
      !this.showPremium &&
      this.showFreeAccountTextIfNoSubscription;
  }
}
