import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[heyreachButton]',
})
export class HeyReachButtonDirective implements OnInit {
  @Input() buttonType:
    | 'primary'
    | 'secondary'
    | 'default'
    | 'pale'
    | 'warn'
    | 'custom-border'
    | 'icon' = 'primary';

  constructor(
    private _el: ElementRef,
    private _renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    this.applyStyles();
  }

  applyStyles(): void {
    const classes = [
      'flex',
      'px-3',
      'py-1',
      'h-11',
      'justify-center',
      'items-center',
      'gap-[10px]',
      'rounded-lg',
      'text-nowrap',
      'opacity-100',
      'hover:opacity-80',
      'cursor-pointer',
    ];

    classes.forEach((x) => this._renderer.addClass(this._el.nativeElement, x));
    switch (this.buttonType) {
      case 'primary':
        this._el.nativeElement.style.backgroundColor = 'var(--primary-color)';
        this._renderer.addClass(this._el.nativeElement, 'text-white');
        break;
      case 'secondary':
        this._el.nativeElement.style.backgroundColor = 'var(--secondary-color)';
        break;
      case 'default':
        this._renderer.addClass(this._el.nativeElement, 'border');
        this._renderer.addClass(this._el.nativeElement, 'border-pale');
        break;
      case 'pale':
        this._renderer.addClass(this._el.nativeElement, 'bg-pale');
        break;
      case 'warn':
        this._el.nativeElement.style.backgroundColor = 'var(--warn-color)';
        this._renderer.addClass(this._el.nativeElement, 'text-white');
        break;
      case 'custom-border':
        this._renderer.addClass(this._el.nativeElement, 'border-2');
        this._renderer.addClass(this._el.nativeElement, 'border-secondary-muted');
        break;
      case 'icon':
        break;
    }
  }
}
