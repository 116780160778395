import { Injectable } from '@angular/core';
import { AddLeadsFromMemberIdsOrInboxMode } from '@shared/components/dialogs/add-leads-to-inbox/add-leads-from-member-ids-or-inbox-dialog.component';
import { InboxService } from '@shared/inbox-service/inbox.service';
import {
  ConversationLinkedInProfileIdDto,
  CrmExportLeadProperties,
  ExportCrmInboxContactsDto,
  InboxConversationDto,
  InboxFiltersDto,
  LinkedInAccountDto,
  LinkedInAccountServiceProxy,
  LnkLinkedInProfileTagDto,
  MemberAndAccountIdDto,
} from '@shared/service-proxies/service-proxies';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class InboxStateService {
  private selectedChatsCount: number = 0;
  private readonly selectedChats$$ = new BehaviorSubject<InboxConversationDto[]>([]);
  public readonly selectedChats$ = this.selectedChats$$.asObservable();

  private readonly selectedAll$$ = new BehaviorSubject<boolean>(false);
  public readonly selectedAll$;
  public get selectedAll() {
    return this.selectedAll$$.value;
  }
  public set selectedAll(flag: boolean) {
    this.selectedAll$$.next(flag);
  }

  public getSelectedChats = () => this.selectedChats$$.value;
  public getSelectedChatCorrespondentProfiles = () =>
    this.selectedChats$$.value.map((x) => x.correspondentProfile).map((x) => x.id);
  public getSelectedChatCorrespondentProfilesIds = () =>
    this.selectedChats$$.value.map((x) => x.correspondentProfile).map((x) => x.id);

  public readonly accounts$: Observable<LinkedInAccountDto[]>;
  constructor(
    public _inboxService: InboxService,
    private _accountsService: LinkedInAccountServiceProxy,
  ) {
    this.accounts$ = this._accountsService
      .getLinkedInAccountsForTennant()
      .pipe(
        map((linkedinAccounts) =>
          linkedinAccounts.filter((linkedinAccount) => linkedinAccount.authIsValid),
        ),
      );

    this.selectedAll$ = this.selectedAll$$.asObservable();
  }

  public readonly selectedChatsCount$ = this.selectedChats$.pipe(
    map((selectedChats) => selectedChats.length),
    tap((selectedChatsCount) => (this.selectedChatsCount = selectedChatsCount)),
  );

  public set selectedChats(selectedChats: InboxConversationDto[]) {
    this.selectedChats$$.next(selectedChats);
  }

  getExcludedProfileIds() {
    const selectedChats = this.selectedChats$$.getValue();
    const selectedProfileIds = selectedChats.map((x) => x.correspondentProfile.id);
    const currentChatRooms = this._inboxService
      .getCurrentChatRooms()
      .map((i) => i.correspondentProfile.id);

    return currentChatRooms.filter((item) => !selectedProfileIds.includes(item));
  }
  public updateSelectedChats(chat: InboxConversationDto, value: boolean) {
    const oldSelectedChatsValue = this.selectedChats$$.getValue();
    if (value) {
      this.selectedChats$$.next([...oldSelectedChatsValue, chat]);
    } else {
      this.selectedChats$$.next(
        oldSelectedChatsValue.filter((x) => x.id != chat.id || x.accountId != chat.accountId),
      );
    }
    // this.selectedAll =
    //   this.selectedChats$$.getValue().length === this._inboxService.getCurrentChatRooms().length;
  }

  public changeSelectionOnAllChats(value: boolean) {
    if (value) {
      this.selectedChats$$.next([...this._inboxService.getCurrentChatRooms()]);
    } else {
      this.selectedChats$$.next([]);
    }
  }

  exportDataToCrm(): ExportCrmInboxContactsDto {
    return {
      inboxFilters: this._inboxService.filters,
      excludeProfileIds: this.getSelectedChatCorrespondentProfilesIds(),

      crmInfoId: null,
      exportLeadProperties: new CrmExportLeadProperties(),
    } as ExportCrmInboxContactsDto;
  }

  openCrmWithSelectedChats(): ConversationLinkedInProfileIdDto[] {
    return this.getSelectedChats()
      .filter((x: InboxConversationDto) => x.correspondentMemberId)
      .map((x) => {
        return new ConversationLinkedInProfileIdDto({
          conversationId: x.id,
          memberId: x.correspondentMemberId,
          accountId: x.accountId,
        });
      });
  }

  openAddToListDialog() {
    const mode: AddLeadsFromMemberIdsOrInboxMode = this.selectedAll
      ? 'FROM_INBOX'
      : 'FROM_MEMBER_IDS';

    return {
      mode: mode,
      memberIds: this.getSelectedChatCorrespondentProfiles(),
      inboxFilters: this._inboxService.filters,
      excludeMemberIds: this.getSelectedChatCorrespondentProfilesIds(),
      leadsCount: !this.selectedAll ? this.selectedChatsCount : this._inboxService.totalChatRooms,
    };
  }
  addToCampaignData(): {
    inboxFilters: InboxFiltersDto;
    excludeMemberIds: string[];
    leadsToAdd: MemberAndAccountIdDto[];
    exportType: string;
    leadsCount: number;
  } {
    const exportType = this.selectedAll ? 'INBOX' : 'CONTACTS';
    return {
      inboxFilters: this._inboxService.filters,
      excludeMemberIds: this.getSelectedChatCorrespondentProfilesIds(),
      leadsToAdd: this.getSelectedChats().map(
        (x) =>
          new MemberAndAccountIdDto({
            linkedInAccountId: x.accountId,
            memberId: x.correspondentMemberId,
          }),
      ),
      exportType: exportType,
      leadsCount: this.selectedAll ? this._inboxService.totalChatRooms : this.selectedChatsCount,
    };
  }

  assignTag(tags: LnkLinkedInProfileTagDto[]) {
    const selectedChat = this._inboxService.selectedChatValue();
    selectedChat.correspondentProfile.tagLinks = [
      ...selectedChat.correspondentProfile.tagLinks,
      ...tags,
    ];
    this._inboxService.updateChat(this._inboxService.selectedChatValue());
    const getCurrentChatRooms = this._inboxService.getCurrentChatRooms();

    const findCurrentChatRoom = getCurrentChatRooms.find(
      (chat) => chat.id === selectedChat?.id && chat.accountId === selectedChat?.accountId,
    );

    findCurrentChatRoom.correspondentProfile.tagLinks = [
      ...selectedChat.correspondentProfile?.tagLinks,
      ...tags,
    ];

    this._inboxService.chatRooms = getCurrentChatRooms;
  }

  unAssignTag(tagId: number) {
    const selectedChat = this._inboxService.selectedChatValue();

    this._inboxService.selectedChatValue().correspondentProfile.tagLinks = this._inboxService
      .selectedChatValue()
      .correspondentProfile.tagLinks.filter((x) => x.profileTagId !== tagId);
    this._inboxService.updateChat(this._inboxService.selectedChatValue());

    const getCurrentChatRooms = this._inboxService.getCurrentChatRooms();

    const findCurrentChatRoom = getCurrentChatRooms.find(
      (chat) => chat.id === selectedChat?.id && chat.accountId === selectedChat?.accountId,
    );

    findCurrentChatRoom.correspondentProfile.tagLinks = this._inboxService
      .selectedChatValue()
      .correspondentProfile.tagLinks.filter((x) => x.profileTagId !== tagId);
    this._inboxService.chatRooms = getCurrentChatRooms;
  }
}

export const deepCopyEquals = (prev: any, curr: any): boolean =>
  JSON.stringify(prev) === JSON.stringify(curr);
