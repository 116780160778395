import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';

@Component({
  selector: 'status-badge',
  templateUrl: './status-badge.component.html',
  animations: [appModuleAnimation()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusBadgeComponent {
  @Input() status: StatusBadgeStatusType = 'default';
}

export type StatusBadgeStatusType =
  | 'default'
  | 'danger'
  | 'warning'
  | 'success'
  | 'progress'
  | 'iddle'
  | 'queued';
