export class AppConsts {
  static remoteServiceBaseUrl: string;
  static appBaseUrl: string;
  static appBaseHref: string; // returns angular's base-href parameter value if used during the publish

  static stripeApiKey: string;

  static localeMappings: any = [];

  static extensionId: string;
  static extensionDownloadUrl: string;

  static readonly userManagement = {
    defaultAdminUserName: 'admin',
  };

  static readonly localization = {
    defaultLocalizationSourceName: 'Spremo',
  };

  static readonly authorization = {
    encryptedAuthTokenName: 'enc_auth_token',
  };

  // User interface consts
  static readonly minimumLettersToSearch: number = 3;
  static readonly searchInputDebounceTimeMillis: number = 350;

  // Extension info

  // Payment success & cancellation
  static readonly paymentEventRedirectTimeMilis = 3000;

  static readonly HeyReachEventType = {
    CONNECTIONS_SENT: 'Connections Sent',
    CONNECTIONS_ACCEPTED: 'Connections Accepted',
    MESSAGES_SENT: 'Messages Sent',
    MESSAGE_REPLIES: 'Message Replies',
    INMAILS_SENT: 'InMails Sent',
    INMAIL_REPLIES: 'InMail Replies',
  };

  static readonly requestDebounceTimeMilis = 200;

  //SignalR
  static signalRServiceBaseUrl: string;
  static inboxConnectionHeartbeatIntervalMs = 60000; // 1 minute
}
