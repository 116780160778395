import { EventEmitter, Injectable } from '@angular/core';
import { HubConnection } from '@aspnet/signalr';
import { AppMessageType } from '@shared/AppEnums';
import {
  ConversationDto,
  ConversationMessagesResponseDto,
  GetAllChatRoomsInputDto,
  InboxConversationDto,
  InboxFiltersDto,
  InboxServiceProxy,
  LinkedInProfileTagDto,
  LnkLinkedInProfileTagDto,
  SendMessageDto,
} from '@shared/service-proxies/service-proxies';
import { PermissionCheckerService } from 'abp-ng2-module';
import { Dictionary } from 'lodash';
import * as moment from 'moment';
import { Observable, Subscription, of } from 'rxjs';
import { ChatDetails } from './inbox.types';
import { BehaviorSubject } from '@node_modules/rxjs';
import { Router } from '@node_modules/@angular/router';
import { filter, map } from 'rxjs/operators';
import { NavigationEnd } from '@angular/router';

@Injectable()
export class InboxService {
  public onInboxChange = new EventEmitter();
  public initialized: boolean = false;
  public inboxHub: HubConnection;
  enabled: boolean;
  public totalChatRooms: number = 0;
  //TODO: Why do we have this chat details here? (Kiril)
  public chatDetails: Dictionary<ChatDetails> = {};
  public limit: number = 20;
  // Filters
  public filters: InboxFiltersDto = new InboxFiltersDto({
    linkedInAccountIds: [],
    campaignIds: [],
    tagIds: [],
    searchString: '',
    conversationType: null,
    notReplied: null,
    seen: null,
    sortBy: 0,
  });
  getAllChatRoomsSub: Subscription;
  // public chatRoomsLoading = true;
  private loading: boolean = false;
  private page: number = 0;
  private new: number = 0;
  private sortBy: number = 0;
  private readonly conversationMessagesSubject$ =
    new BehaviorSubject<ConversationMessagesResponseDto>(new ConversationMessagesResponseDto());
  public readonly conversationMessages$ = this.conversationMessagesSubject$.asObservable();
  private readonly selectedChatSubject$ = new BehaviorSubject<InboxConversationDto>(undefined);
  public readonly selectedChat$ = this.selectedChatSubject$.asObservable();

  private readonly totalChatRooms$$ = new BehaviorSubject<number>(null);
  public readonly totalChatRooms$ = this.totalChatRooms$$.asObservable();

  // TODO: Why is everything of type InboxConversationDto????
  private readonly chatRoomsSubject$ = new BehaviorSubject<InboxConversationDto[]>([]);
  public readonly chatRooms$: Observable<InboxConversationDto[]> =
    this.chatRoomsSubject$.asObservable();

  private readonly initialLoadingSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );
  public readonly initialLoading$: Observable<boolean> = this.initialLoadingSubject$.asObservable();
  public getCurrentChatRooms = () => this.chatRoomsSubject$.value;

  public set chatRooms(selectedChats: InboxConversationDto[]) {
    this.chatRoomsSubject$.next([
      ...selectedChats.map(
        (chat) =>
          ({
            ...chat,
            linkedInAccount: {
              ...chat.linkedInAccount,
            },
            correspondentProfile: {
              tagLinks: {
                ...chat.correspondentProfile.tagLinks,
              },
              ...chat.correspondentProfile,
            },
            ...chat,
          }) as InboxConversationDto,
      ),
    ]);
  }

  private readonly selectedChatDetailsSubject$ = new BehaviorSubject<ChatDetails>(null);
  public readonly selectedChatDetails$ = this.selectedChatDetailsSubject$.asObservable();

  private readonly searchTerm$$ = new BehaviorSubject<string>(null);
  public readonly searchTerm$ = this.searchTerm$$.asObservable();

  constructor(
    private _inboxService: InboxServiceProxy,
    private _permissionChecker: PermissionCheckerService,
    private _router: Router,
  ) {
    this.enabled = this._permissionChecker.isGranted('Pages.Inbox');
    // DEBUG
    this.enabled = true;

    if (this.enabled) {
      void this.refresh();
    }

    _router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(async (event) => {
        const e = event as NavigationEnd;
        if (e.url.startsWith('/app/inbox')) {
          // get preselected chatroom
          const accountId = e.url.split('/')[3];
          const conversationId = decodeURIComponent(e.url.split('/')[4]);

          if (!accountId || !conversationId) {
            this.selectedChatSubject$.next(null);
            return;
          }

          const chat: InboxConversationDto = await this.getChat(
            parseInt(accountId),
            conversationId,
          );

          if (!chat) {
            this.selectedChatSubject$.next(null);
            void this._router.navigateByUrl('app/inbox');
            return;
          }

          // todo: ffs fix this, it is updated both here and in the getChat function...
          this.selectedChatSubject$.next({ ...chat } as InboxConversationDto);
          await this.getMessages(chat.accountId, chat.id);
          this.selectedChatDetailsSubject$.next({
            ...this.chatDetails[chat?.id + chat?.accountId],
          } as ChatDetails);
        }
      });
  }

  private _unreadMessages = 0;

  public get unreadMessages() {
    return this._unreadMessages;
  }

  public set unreadMessages(value) {
    this._unreadMessages = value;
    this.onInboxChange.emit();
  }

  public get hasFilters(): boolean {
    return (
      this.filters.campaignIds.length > 0 ||
      this.filters.linkedInAccountIds.length > 0 ||
      this.filters.tagIds.length > 0 ||
      !!this.filters.searchString ||
      this.filters.conversationType !== null
    );
  }

  public readonly selectedChatValue = () => this.selectedChatSubject$.value;
  public updateChat(chat: Partial<InboxConversationDto>) {
    const oldValue = this.selectedChatSubject$.value;
    const newValue = {
      ...oldValue,
      linkedInAccount: {
        ...chat.linkedInAccount,
      },
      correspondentProfile: {
        tagLinks: {
          ...chat.correspondentProfile.tagLinks,
        },
        ...chat.correspondentProfile,
      },
      ...chat,
    };
    this.selectedChatSubject$.next(newValue as InboxConversationDto);
  }

  public readonly chatRoomsValue = () => this.chatRoomsSubject$.value;
  public readonly selectedChatDetailsValue = () => this.selectedChatDetailsSubject$.value;

  public async clearChatrooms() {
    this.initialLoadingSubject$.next(true);
    this.page = 0;
    this.new = 0;
    await this.refresh();
    this.initialLoadingSubject$.next(false);
  }

  public clearChatRoomsBeforeApplyingFilters() {
    this.chatRoomsSubject$.next([]);
  }

  public updateTag(tag: LinkedInProfileTagDto) {
    if (tag) {
      const chatRoomsUpdated: InboxConversationDto[] = this.chatRoomsValue().map((chatRoom) =>
        this.updateTagOnChat(tag, chatRoom),
      );
      this.chatRoomsSubject$.next(chatRoomsUpdated);

      const updateSelectedChatTag = this.updateTagOnChat(tag, this.selectedChatSubject$.value);

      this.selectedChatSubject$.next(updateSelectedChatTag);
    }
  }

  private updateTagOnChat(
    tag: LinkedInProfileTagDto,
    chatRoom: InboxConversationDto,
  ): InboxConversationDto {
    const found = chatRoom.correspondentProfile?.tagLinks.find((z) => z.profileTagId == tag.id);
    if (found) {
      found.profileTag.colorHex = tag.colorHex;
      found.profileTag.displayName = tag.displayName;
    }
    const chatRoomUpdated = {
      ...chatRoom,
      correspondentProfile: {
        ...chatRoom.correspondentProfile,
        tagLinks: chatRoom.correspondentProfile?.tagLinks.map((z) => {
          if (z.profileTagId == tag.id) {
            z.profileTag.colorHex = tag.colorHex;
            z.profileTag.displayName = tag.displayName;
          }
          return z;
        }),
      },
    };
    return chatRoomUpdated as InboxConversationDto;
  }
  public assignTagsToChatrooms(tags: LnkLinkedInProfileTagDto[]) {
    tags.forEach((x) => {
      const chatrooms = this.chatRoomsValue().filter((z) => z.correspondentMemberId == x.profileId);

      chatrooms.forEach((chatroom) => {
        if (!chatroom?.correspondentProfile?.tagLinks?.includes(x)) {
          chatroom?.correspondentProfile?.tagLinks?.push(x);
        }
      });
    });
  }

  public unAssignTagFromAllChatrooms(tagId: number) {
    this.chatRoomsValue()
      .filter((x) => x.correspondentProfile?.tagLinks.find((z) => z.profileTagId == tagId))
      .forEach((x) => {
        x.correspondentProfile.tagLinks = x.correspondentProfile?.tagLinks.filter(
          (z) => z.profileTagId != tagId,
        );
      });
  }

  public unAssignTagFromSpecificChatrooms(tagId: number, conversations: InboxConversationDto[]) {
    this.chatRoomsValue()
      .filter((x) => conversations.find((z) => z.accountId == x.accountId && z.id == x.id))
      .filter((x) => x.correspondentProfile?.tagLinks.find((z) => z.profileTagId == tagId))
      .forEach((x) => {
        x.correspondentProfile.tagLinks = x.correspondentProfile?.tagLinks.filter(
          (z) => z.profileTagId != tagId,
        );
      });
  }

  unAssignTagForProfiles(tagId: number, profileIds: string[]) {
    const chatrooms = this.chatRoomsValue().filter((z) =>
      profileIds.includes(z.correspondentMemberId),
    );

    chatrooms.forEach((chatroom) => {
      chatroom.correspondentProfile.tagLinks = chatroom.correspondentProfile?.tagLinks.filter(
        (z) => z.profileTagId != tagId,
      );
    });
  }

  // Public methods
  public async loadNextPage() {
    if (this.loading) {
      return;
    }

    this.loading = true;

    this.page++;

    await this.refreshUnreadMessages();

    this.loading = false;
  }

  public async refresh() {
    // Get messages from db and stuff
    await this.refreshUnreadMessages();
    this.getTotalUnreadMessages();
  }

  public getTotalUnreadMessages() {
    this._inboxService.countUnseenChatRooms().subscribe((numberOfUnreadMessages) => {
      this.unreadMessages = numberOfUnreadMessages;
    });
  }

  //TODO: One of the worst function I have ever seen (Kiril)
  public async refreshUnreadMessages(customLimit?: number, additionalOffset: number = 0) {
    return new Promise<void>((resolve, reject) => {
      if (this.getAllChatRoomsSub) {
        this.getAllChatRoomsSub.unsubscribe();
      }

      const input = new GetAllChatRoomsInputDto();
      input.offset = this.limit * this.page; // - this.seen + this.new;
      input.limit = this.limit;
      input.filters = new InboxFiltersDto(this.filters);

      if (customLimit) {
        input.offset += customLimit + additionalOffset;
        input.limit = customLimit;
      }

      this.getAllChatRoomsSub = this._inboxService.getChatrooms(input).subscribe(
        (result) => {
          resolve();

          this.totalChatRooms = result.totalCount;
          this.totalChatRooms$$.next(result.totalCount);
          //TODO: THIS is probably crap and not needed check with Dimitar
          if (customLimit == null && result.items.length == 0) {
            const chatRooms = this.chatRoomsValue() ?? [];
            if (chatRooms.length === result.totalCount) {
              this.chatRoomsSubject$.next(chatRooms);
            } else {
              this.chatRoomsSubject$.next(result.items);
            }

            this.page--;
            return;
          }

          let totalExistingChats = 0;
          // concat only those that are not existing
          result.items.forEach((chatRoom) => {
            // find existing chatroom by id
            const existing = this.getChatRoomById(chatRoom.accountId, chatRoom.id);
            if (existing) {
              totalExistingChats++;
            } else {
              // TODO: This is done because there was a bug that doesn't filter the chat rooms when searching so This checks if the total items is
              // less then the previous chatRoomsItemsLegnth Meaning that filters are being applied and they are being reduced
              const chatRooms =
                result.totalCount < this.chatRoomsValue().length ? [] : this.chatRoomsValue();
              chatRooms.push(chatRoom);
              this.chatRoomsSubject$.next([...chatRooms]);
              this.afterAddedChatRoom(chatRoom);
            }
          });
          // this.chatRoomsLoading = false;

          //TODO: This here works in mysterious ways
          if (totalExistingChats > 0) {
            this.chatRoomsSubject$.next([...result.items]);
          } else {
            // this.updateIcons();
          }

          this.initialized = true;
        },
        () => {
          reject();
        },
      );
    });
  }

  upsertChatRooms(
    chatRooms: { conversation: InboxConversationDto; messages: ConversationMessagesResponseDto }[],
  ) {
    if (!this.enabled) {
      return;
    }

    let newMessages = 0;
    chatRooms.forEach((chatRoom) => {
      const conversation: InboxConversationDto = chatRoom.conversation;
      // check for unread messages
      newMessages += conversation.unreadMessageCount;

      // find existing chatroom by id
      let existing = this.getChatRoomById(conversation.accountId, conversation.id);

      if (existing) {
        existing = {
          ...conversation,
          lastCorrespondentSeenAt: moment(conversation.lastCorrespondentSeenAt),
          lastActivityAt: moment(conversation.lastActivityAt),
          lastMessageAt: moment(conversation.lastMessageAt),
          lastReadAt: moment(conversation.lastReadAt),
        } as InboxConversationDto;

        this.chatRoomsSubject$.next([
          existing,
          ...this.chatRoomsValue().filter((_chatRoom) => _chatRoom.id !== existing.id),
        ]);
        this.afterAddedChatRoom(existing);
      } else {
        // FILTER

        if (!this.matchFilters(conversation)) {
          return;
        }

        // FILTER

        if (this.chatRoomsValue().length > 20 && conversation.read) {
          return;
        }

        conversation.lastActivityAt = moment(conversation.lastActivityAt);
        conversation.lastCorrespondentSeenAt = moment(conversation.lastCorrespondentSeenAt);
        conversation.lastMessageAt = moment(conversation.lastMessageAt);
        conversation.lastReadAt = moment(conversation.lastReadAt);

        // create new chatroom
        this.chatRoomsSubject$.next([conversation, ...this.chatRoomsSubject$.value]);
        this.afterAddedChatRoom(conversation);
        this.new++;
      }

      if (this.selectedChatSubject$.value?.id == chatRoom.conversation.id) {
        chatRoom.messages.messages = chatRoom.messages.messages.map((x) => {
          return {
            ...x,
            createdAt: moment(x.createdAt),
          } as ConversationDto;
        });
        this.conversationMessagesSubject$.next({
          ...chatRoom.messages,
        } as ConversationMessagesResponseDto);
      }
    });

    // this.sortChats();

    if (newMessages > 0) {
      this.unreadMessages += newMessages;
      // this.alertNewMessages(newMessages);
    }

    // this.updateIcons();
  }

  async getChat(accountId: number, chatRoomId: string): Promise<InboxConversationDto> {
    const existing: InboxConversationDto = this.getChatRoomById(accountId, chatRoomId);
    if (existing) {
      this.selectedChatSubject$.next({ ...existing } as InboxConversationDto);
      return existing;
    } else {
      const chatRoom: InboxConversationDto = await this._inboxService
        .getChatroom(accountId, chatRoomId)
        .toPromise();
      if (chatRoom && chatRoom.id) {
        this.chatRoomsSubject$.next([chatRoom, ...this.chatRoomsValue()]);
        this.afterAddedChatRoom(chatRoom);
        this.selectedChatSubject$.next({ ...chatRoom } as InboxConversationDto);
        return chatRoom;
      }
    }
  }

  afterAddedChatRoom(chatRoom: InboxConversationDto) {
    if (!this.chatDetails[chatRoom.id + chatRoom.accountId]) {
      this.chatDetails[chatRoom.id + chatRoom.accountId] = {
        message: '',
      } as ChatDetails;
    }
  }

  async getMessages(accountId: number, chatRoomId: string): Promise<void> {
    const chat: ConversationMessagesResponseDto = await this._inboxService
      .getConversation(accountId, chatRoomId)
      .toPromise();
    this.conversationMessagesSubject$.next(chat);
  }

  public sendMessage(chatDetails: ChatDetails): Observable<boolean> {
    if (!this.enabled) {
      return;
    }

    const selectedChatRoom: InboxConversationDto = this.selectedChatSubject$.value;
    if (selectedChatRoom == undefined) {
      return of(false);
    }

    // validate message
    chatDetails.message = chatDetails.message.trim();
    if (
      (chatDetails.message == null || chatDetails.message === '') &&
      chatDetails.file == undefined
    ) {
      return of(false);
    }

    // send message request
    const textMessage = new SendMessageDto({
      accountId: selectedChatRoom.accountId,
      memberId: selectedChatRoom.correspondentMemberId,
      message: chatDetails.message,
      subject: undefined,
      messageId: undefined,
      nodePath: undefined,
      sequenceId: undefined,
      conversationId: selectedChatRoom.id,
    } as SendMessageDto);

    return this._inboxService.sendMessage(textMessage).pipe(
      map((x: ConversationDto) => {
        if (!x || !x.messageId) {
          return false;
        }
        const messages = this.conversationMessagesSubject$.value;
        messages.messages.push(x);
        this.conversationMessagesSubject$.next(messages);
        selectedChatRoom.lastMessageAt = moment();
        selectedChatRoom.lastMessageSender = 0;
        selectedChatRoom.lastMessageText = chatDetails.message;
        selectedChatRoom.lastMessageType = AppMessageType.Text;
        selectedChatRoom.lastMessageSuccess = x.success;
        this.selectedChatSubject$.next({ ...selectedChatRoom } as InboxConversationDto);
        this.chatRoomsSubject$.next([
          selectedChatRoom,
          ...this.chatRoomsValue().filter((chatRoom) => chatRoom.id !== selectedChatRoom.id),
        ]);

        return true;
      }),
    );
  }

  public async setSeenStatusOnSelectedChat() {
    if (!this.enabled) {
      return;
    }

    const selectedChatRoom = this.selectedChatSubject$.value;

    if (!selectedChatRoom || !selectedChatRoom.read) {
      return;
    }

    selectedChatRoom.read = true;
    selectedChatRoom.lastReadAt = moment();
    selectedChatRoom.unreadMessageCount = 0;
    this.selectedChatSubject$.next({ ...selectedChatRoom } as InboxConversationDto);

    void this._inboxService
      .setSeenStatus(selectedChatRoom.accountId, selectedChatRoom.id)
      .toPromise();
  }

  public matchFilters(chatRoom: InboxConversationDto) {
    if (!this.hasFilters) {
      return true;
    }

    return (
      (this.filters.campaignIds.length == 0 ||
        this.filters.campaignIds.includes(chatRoom.campaignId)) &&
      (this.filters.linkedInAccountIds.length == 0 ||
        this.filters.linkedInAccountIds.includes(chatRoom.accountId)) &&
      (this.filters.tagIds.length == 0 ||
        this.filters.tagIds.some((z) =>
          chatRoom.correspondentProfile.tagLinks.map((x) => x.profileTagId).includes(z),
        )) &&
      (!this.filters.conversationType ||
        this.filters.conversationType == chatRoom.conversationType) &&
      (!this.filters.searchString ||
        chatRoom.correspondentProfile.fullName
          .toLocaleLowerCase()
          .includes(this.filters.searchString.toLowerCase()))
    );
  }

  public refreshDetails(chatRoom: InboxConversationDto) {
    chatRoom['refreshing'] = true;
  }

  public setSelectedChatDetails(chat: ChatDetails) {
    this.selectedChatDetailsSubject$.next({ ...chat } as ChatDetails);
  }

  // Private methods
  private getChatRoomById = (accountId: number, chatRoomId: string) =>
    this.chatRoomsValue()?.find((x) => x.accountId == accountId && x.id === chatRoomId);
}
