import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY } from '@node_modules/rxjs';
import { combineLatest, Observable, Subject, throwError } from 'rxjs';
import {
  ConnectLinkedInAccountInputDto,
  ConnectLinkedInAccountOutputDto,
  ConnectLinkedInAccountPinInputDto,
  ILinkedInAccountDto,
  ILinkedInAccountSlotDto,
  InboxScrapeConfiguration,
  LinkedInAccountDto,
  LinkedInAccountLoginSuccessResponseDto,
  LinkedInAccountServiceProxy,
  LinkedInAccountSlotDto,
  LinkedInAccountSlotDtoPagedResultDto,
  LinkedInAccountSlotServiceProxy,
  LinkedInAccountSlotsFilters,
  LinkedInAccountSlotsInformation,
  LinkedInAccountSlotStatusFilter,
  RecruiterContractDto,
} from '@shared/service-proxies/service-proxies';
import { finalize, switchMap } from '@node_modules/rxjs/operators';
import { catchError, debounceTime, distinctUntilChanged, map, tap } from 'rxjs/operators';
import { InterceptorDisabler } from '@shared/interceptors/disable-interceptor';
import { MatSnackBar } from '@angular/material/snack-bar';
import { startWithTap } from '@shared/pipes/start-with-tap.pipe';
import { ErrorSnackbarComponent } from '@shared/components/error-snackbar/error-snackbar.component';
import { AppLinkedInAccountVerificationType, AppLinkedInChallengeType } from '@shared/AppEnums';
import { CustomProxyService } from '@app/linkedin-accounts/connect-account/custom-proxy-service';
import {
  RecruiterPlanSelectionDialogData,
  RecruiterSubscriptionsDialogComponent,
} from '@app/subscription/recruiter-subscriptions-dialog/recruiter-subscriptions-dialog.component';
import { MatDialogConfig } from '@node_modules/@angular/material/dialog';
import { ComponentType } from '@node_modules/@angular/cdk/portal';

@Injectable()
export class LinkedInAccountsService {
  public accountsWithIncorrectPin$: Observable<{ [key: number]: boolean }>;
  public selectedAccountAuth$: Observable<LinkedInAccountSlotDto>;
  public pinForAccount$: Observable<{ [key: number]: number }>;
  public pageNumber$: Observable<number>;
  public searchString$: Observable<string>;
  public statusFilter$: Observable<LinkedInAccountSlotStatusFilter>;
  public isActiveFilter$: Observable<boolean>;
  public totalSlots$: Observable<number>;
  public slotsLoading$: Observable<boolean>;
  public slots$: Observable<LinkedInAccountSlotDto[]>;
  public slotsInformation$: Observable<LinkedInAccountSlotsInformation>;
  public slotInformationLoading$: Observable<boolean>;
  public usedSlots$: Observable<number>;
  public isSingleLoginInProgress$: Observable<boolean>;
  public slotsInformationRefresh$: Observable<void>;
  public getConnectedAccount$: Observable<boolean>;
  public closeDrawer$: Observable<void>;
  public openDialog$: Observable<{ component: ComponentType<any>; config: MatDialogConfig }>;
  public connectAccountInput$: Observable<ConnectLinkedInAccountInputDto>;
  public isProcessingPin$: Observable<boolean>;
  public clearPinValue$: Observable<void>;
  public linkedInAccountAuthPins$: Observable<{ [key: number]: string }>;
  public slotsWhereOperationIsInProgress$: Observable<number[]>;

  private accountsWithIncorrectPinSubject$ = new BehaviorSubject<{ [key: number]: boolean }>({});
  private selectedAccountAuthSubject$ = new BehaviorSubject<LinkedInAccountSlotDto>(null);
  private pinForAccountSubject$ = new BehaviorSubject<{ [key: number]: number }>({});
  private pageNumberSubject$ = new BehaviorSubject<number>(0);
  private searchStringSubject$ = new BehaviorSubject<string>('');
  private statusFilterSubject$ = new BehaviorSubject<LinkedInAccountSlotStatusFilter>(undefined);
  private isActiveFilterSubject$ = new BehaviorSubject<boolean>(undefined);
  private totalSlotsSubject$ = new BehaviorSubject<number>(0);
  private slotsLoadingSubject$ = new BehaviorSubject<boolean>(false);
  private slotsRefreshSubject$ = new BehaviorSubject<void>(null);
  private slotsRefresh$: Observable<void> = this.slotsRefreshSubject$.asObservable();
  private slotInformationLoadingSubject$ = new BehaviorSubject<boolean>(false);
  private usedSlotsSubject$ = new BehaviorSubject<number>(0);
  private slotsInformationRefreshSubject$ = new BehaviorSubject<void>(null);
  private isSingleLoginInProgressSubject$ = new BehaviorSubject<boolean>(false);
  private connectAccountInputSubject$ = new BehaviorSubject<ConnectLinkedInAccountInputDto>(null);
  private getConnectedAccountSubject$ = new Subject<boolean>();
  private isUsingHeyReachExtensionSubject$ = new BehaviorSubject<boolean>(false);
  private closeDrawerSubject$ = new Subject<void>();
  private openDialogSubject$ = new Subject<{
    component: ComponentType<any>;
    config: MatDialogConfig;
  }>();
  private isProcessingPinSubject$ = new BehaviorSubject<boolean>(false);
  private linkedInAccountAuthPinsSubject$ = new BehaviorSubject<{ [key: number]: string }>({});
  private clearPinValueSubject$ = new Subject<void>();
  private slotsWhereOperationIsInProgressSubject$ = new BehaviorSubject<number[]>([]);

  constructor(
    private linkedInAccountSlotServiceProxy: LinkedInAccountSlotServiceProxy,
    private linkedInAccountServiceProxy: LinkedInAccountServiceProxy,
    private interceptorDisabler: InterceptorDisabler,
    private toaster: MatSnackBar,
    private customProxyService: CustomProxyService,
  ) {
    this.accountsWithIncorrectPin$ = this.accountsWithIncorrectPinSubject$.asObservable();
    this.selectedAccountAuth$ = this.selectedAccountAuthSubject$.asObservable();
    this.pinForAccount$ = this.pinForAccountSubject$.asObservable();
    this.totalSlots$ = this.totalSlotsSubject$.asObservable();
    this.pageNumber$ = this.pageNumberSubject$.asObservable().pipe(distinctUntilChanged());
    this.searchString$ = this.searchStringSubject$.asObservable();
    this.statusFilter$ = this.statusFilterSubject$
      .asObservable()
      .pipe(distinctUntilChanged((x, y) => JSON.stringify(x) === JSON.stringify(y)));
    this.isActiveFilter$ = this.isActiveFilterSubject$.asObservable().pipe(distinctUntilChanged());
    this.slotInformationLoading$ = this.slotInformationLoadingSubject$.asObservable();
    this.slotsLoading$ = this.slotsLoadingSubject$.asObservable();
    this.usedSlots$ = this.usedSlotsSubject$.asObservable();
    this.isSingleLoginInProgress$ = this.isSingleLoginInProgressSubject$.asObservable();
    this.slotsInformationRefresh$ = this.slotsInformationRefreshSubject$.asObservable();
    this.getConnectedAccount$ = this.getConnectedAccountSubject$.asObservable();
    this.closeDrawer$ = this.closeDrawerSubject$.asObservable();
    this.openDialog$ = this.openDialogSubject$.asObservable();
    this.connectAccountInput$ = this.connectAccountInputSubject$.asObservable();
    this.isProcessingPin$ = this.isProcessingPinSubject$.asObservable();
    this.clearPinValue$ = this.clearPinValueSubject$.asObservable();
    this.linkedInAccountAuthPins$ = this.linkedInAccountAuthPinsSubject$.asObservable();
    this.slotsWhereOperationIsInProgress$ =
      this.slotsWhereOperationIsInProgressSubject$.asObservable();

    this.slots$ = combineLatest([
      this.pageNumber$,
      this.searchString$,
      this.statusFilter$,
      this.isActiveFilter$,
      this.slotsRefresh$,
    ]).pipe(
      debounceTime(350),
      tap(() => {
        this.slotsLoadingSubject$.next(true);
      }),
      switchMap(([pageNumber, searchString, statusFilter, isActiveFilter]) => {
        return this.linkedInAccountSlotServiceProxy.getPaginatedSlots(
          new LinkedInAccountSlotsFilters({
            status: statusFilter,
            pageNumber: pageNumber,
            searchString: searchString,
            isActive: isActiveFilter,
          }),
        );
      }),
      tap((res: LinkedInAccountSlotDtoPagedResultDto) => {
        this.totalSlotsSubject$.next(res.totalCount);
      }),
      map((res: LinkedInAccountSlotDtoPagedResultDto) => res.items),
      tap(() => {
        this.slotsLoadingSubject$.next(false);
      }),
    );

    this.slotsInformation$ = this.slotsInformationRefresh$.pipe(
      tap(() => this.slotInformationLoadingSubject$.next(true)),
      switchMap(() => {
        return this.linkedInAccountSlotServiceProxy.getSlotsInformation();
      }),
      tap((res: LinkedInAccountSlotsInformation) => {
        this.usedSlotsSubject$.next(res.totalSlots - res.availableSlots);
        this.slotInformationLoadingSubject$.next(false);
      }),
    );
  }

  public verifyPinClicked(slot: LinkedInAccountSlotDto): void {
    this.selectedAccountAuthSubject$.next(slot);
  }

  public linkAccountClicked(): void {
    this.selectedAccountAuthSubject$.next(null);
  }

  public changePageNumber(pageNumber: number) {
    this.pageNumberSubject$.next(pageNumber);
  }

  public loadLinkedInSlots() {
    this.clearSlotFilters();
    this.slotsRefreshSubject$.next();
  }

  public reloadSlots() {
    this.slotsRefreshSubject$.next();
  }

  public reloadSlotsInformation() {
    this.slotsInformationRefreshSubject$.next();
  }

  public keywordSearch(searchString: string) {
    this.searchStringSubject$.next(searchString);
    this.pageNumberSubject$.next(0);
  }

  public selectStatusFilter(statusFilter: LinkedInAccountSlotStatusFilter) {
    this.statusFilterSubject$.next(statusFilter);
    this.pageNumberSubject$.next(0);
  }

  public setPinValidityForSelectedAccount(pinValidity: boolean) {
    const currValue: { [key: number]: boolean } = this.accountsWithIncorrectPinSubject$.value;
    currValue[this.selectedAccountAuthSubject$.value.linkedInAccountId] = pinValidity;
    this.accountsWithIncorrectPinSubject$.next({
      ...currValue,
    });
  }

  public connectAccount() {
    this.isSingleLoginInProgressSubject$.next(true);
    this.interceptorDisabler.disable();
    return this.linkedInAccountServiceProxy
      .createLinkedInAccount({
        ...this.connectAccountInputSubject$.value,
        customProxy: this.customProxyService.proxyInput,
      } as ConnectLinkedInAccountInputDto)
      .pipe(
        tap((res) => {
          this.accountsWithIncorrectPinSubject$.next({
            ...this.accountsWithIncorrectPinSubject$.value,
            [res.linkedInAccountId]: true,
          });
        }),
        catchError((err) => {
          let errorString: string;
          try {
            errorString =
              JSON.parse(err.response)?.error?.message ||
              'An error occurred while trying to connect the account.';
          } catch (e) {
            // there is a parsing error when we get a 504 error response
            errorString = 'An error occurred while trying to connect the account.';
          }

          const snackbar = this.toaster.openFromComponent(ErrorSnackbarComponent, {
            duration: 5000,
            panelClass: 'error-snackbar',
          });
          snackbar.instance.message = errorString;
          this.isSingleLoginInProgressSubject$.next(false);
          return EMPTY;
        }),
        tap((res: ConnectLinkedInAccountOutputDto) => {
          this.isSingleLoginInProgressSubject$.next(false);
          switch (res.verificationType) {
            case AppLinkedInAccountVerificationType.NONE: {
              this.toaster.open('Account linked successfully!', undefined, {
                horizontalPosition: 'center',
                verticalPosition: 'bottom',
                duration: 3500,
              });
              this.isUsingHeyReachExtensionSubject$.next(false);
              this.customProxyService.resetProxyErrorMessage();
              this.closeDrawerSubject$.next();
              this.checkForMultipleRecruiterPlans(res.linkedInAccountId, res.recruiterContracts);
              break;
            }
            case AppLinkedInAccountVerificationType.PIN: {
              this.selectedAccountAuthSubject$.next(
                new LinkedInAccountSlotDto({
                  linkedInAccountId: res.linkedInAccountId,
                  linkedInAccount: new LinkedInAccountDto({
                    challengeType: AppLinkedInChallengeType.PIN,
                  } as ILinkedInAccountDto),
                } as ILinkedInAccountSlotDto),
              );
              break;
            }
            case AppLinkedInAccountVerificationType.AUTHENTICATOR: {
              this.selectedAccountAuthSubject$.next(
                new LinkedInAccountSlotDto({
                  linkedInAccountId: res.linkedInAccountId,
                  linkedInAccount: new LinkedInAccountDto({
                    challengeType: AppLinkedInChallengeType.AUTHENTICATOR,
                  } as ILinkedInAccountDto),
                } as ILinkedInAccountSlotDto),
              );
              break;
            }
          }

          this.loadLinkedInSlots();
          this.reloadSlotsInformation();
          this.customProxyService.updateCustomProxyState(undefined);
          this.customProxyService.resetState();
          this.connectAccountInputSubject$.next(new ConnectLinkedInAccountInputDto());
        }),
      );
  }

  public verifyPin() {
    this.isProcessingPinSubject$.next(true);
    return this.linkedInAccountServiceProxy
      .finishLinkedInLoginWithPin(
        new ConnectLinkedInAccountPinInputDto({
          pin: this.linkedInAccountAuthPinsSubject$.value[
            this.selectedAccountAuthSubject$.value.linkedInAccountId
          ],
          linkedInAccountId: this.selectedAccountAuthSubject$.value.linkedInAccountId,
        }),
      )
      .pipe(
        finalize(() => {
          this.isProcessingPinSubject$.next(false);
        }),
        startWithTap(() => {
          this.interceptorDisabler.disable();
        }),
        catchError((error) => {
          const err = JSON.parse(error.response);
          this.setPinValidityForSelectedAccount(false);
          this.clearPinValueSubject$.next();
          const errorString =
            err?.error?.message || 'An error occurred while trying to connect the account.';
          const snackbar = this.toaster.openFromComponent(ErrorSnackbarComponent, {
            duration: 5000,
            panelClass: 'error-snackbar',
          });
          snackbar.instance.message = errorString;
          return throwError(error);
        }),
        tap((res: LinkedInAccountLoginSuccessResponseDto) => {
          this.setPinValidityForSelectedAccount(true);
          this.toaster.open('Account linked successfully!', undefined, {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 3500,
          });
          this.clearPinValueSubject$.next();
          this.isUsingHeyReachExtensionSubject$.next(false);
          this.loadLinkedInSlots();
          this.closeDrawerSubject$.next();
          this.connectAccountInputSubject$.next(new ConnectLinkedInAccountInputDto());
          this.customProxyService.resetState();
          this.checkForMultipleRecruiterPlans(
            this.selectedAccountAuthSubject$.value.linkedInAccountId,
            res.recruiter.recruiterContracts,
          );
        }),
      );
  }

  reLogAccount(slot: LinkedInAccountSlotDto) {
    this.slotsWhereOperationIsInProgressSubject$.next([
      ...this.slotsWhereOperationIsInProgressSubject$.value,
      slot.id,
    ]);
    this.accountsWithIncorrectPinSubject$.next({
      ...this.accountsWithIncorrectPinSubject$.value,
      [slot.linkedInAccountId]: true,
    });
    return this.linkedInAccountServiceProxy.reloginAccount(slot.linkedInAccountId).pipe(
      finalize(() => {
        this.slotsWhereOperationIsInProgressSubject$.next([
          ...this.slotsWhereOperationIsInProgressSubject$.value.filter((x) => x != slot.id),
        ]);
      }),
      tap((res) => {
        if (
          res.verificationType == AppLinkedInAccountVerificationType.PIN ||
          res.verificationType == AppLinkedInAccountVerificationType.AUTHENTICATOR
        ) {
          const snackbar = this.toaster.openFromComponent(ErrorSnackbarComponent, {
            duration: 5000,
            panelClass: 'error-snackbar',
          });
          snackbar.instance.message = 'A PIN is required to finish the Re-Login process!';
          this.reloadSlots();
          return throwError(res);
        }

        this.reloadSlots();
        this.toaster.open('Successfully re-logged account!', undefined, { duration: 4000 });
      }),
    );
  }

  public disconnectAccount(slot: LinkedInAccountSlotDto) {
    this.slotsWhereOperationIsInProgressSubject$.next([
      ...this.slotsWhereOperationIsInProgressSubject$.value,
      slot.id,
    ]);
    return this.linkedInAccountServiceProxy.deleteLinkedInAccount(slot.linkedInAccountId).pipe(
      finalize(() => {
        this.slotsWhereOperationIsInProgressSubject$.next([
          ...this.slotsWhereOperationIsInProgressSubject$.value.filter((x) => x != slot.id),
        ]);
      }),
      tap(() => {
        this.reloadSlots();
        this.reloadSlotsInformation();
        this.toaster.open('Successfully disconnected account!', undefined, { duration: 4000 });
      }),
    );
  }

  public reLogSalesNavigator(slot: LinkedInAccountSlotDto) {
    this.slotsWhereOperationIsInProgressSubject$.next([
      ...this.slotsWhereOperationIsInProgressSubject$.value,
      slot.id,
    ]);
    return this.linkedInAccountServiceProxy.relogSalesNavigator(slot.linkedInAccountId).pipe(
      finalize(() => {
        this.slotsWhereOperationIsInProgressSubject$.next([
          ...this.slotsWhereOperationIsInProgressSubject$.value.filter((x) => x != slot.id),
        ]);
      }),
      tap(() => {
        this.reloadSlots();
        this.toaster.open('Successfully re-logged sales navigator!', undefined, {
          duration: 4000,
        });
      }),
    );
  }

  public reLogRecruiter(slot: LinkedInAccountSlotDto) {
    this.slotsWhereOperationIsInProgressSubject$.next([
      ...this.slotsWhereOperationIsInProgressSubject$.value,
      slot.id,
    ]);
    return this.linkedInAccountServiceProxy.relogRecruiter(slot.linkedInAccountId).pipe(
      finalize(() => {
        this.slotsWhereOperationIsInProgressSubject$.next([
          ...this.slotsWhereOperationIsInProgressSubject$.value.filter((x) => x != slot.id),
        ]);
      }),
      startWithTap(() => {
        this.interceptorDisabler.disable();
      }),
      tap(() => {
        this.reloadSlots();
        this.toaster.open('Successfully re-logged recruiter!', undefined, { duration: 4000 });
      }),
      catchError(() => {
        const snackbar = this.toaster.openFromComponent(ErrorSnackbarComponent, {
          duration: 5000,
          panelClass: 'error-snackbar',
        });
        snackbar.instance.message = 'Unable to re-log recruiter!';
        return EMPTY;
      }),
    );
  }

  public reactivateAccount(slot: LinkedInAccountSlotDto) {
    this.slotsWhereOperationIsInProgressSubject$.next([
      ...this.slotsWhereOperationIsInProgressSubject$.value,
      slot.id,
    ]);
    return this.linkedInAccountSlotServiceProxy
      .reactivateInactiveAccount(slot.linkedInAccountId)
      .pipe(
        finalize(() => {
          this.slotsWhereOperationIsInProgressSubject$.next([
            ...this.slotsWhereOperationIsInProgressSubject$.value.filter((x) => x != slot.id),
          ]);
        }),
        tap(() => {
          this.reloadSlots();
          this.toaster.open('Successfully reactivated account!', undefined, { duration: 4000 });
        }),
      );
  }

  public selectedInboxOptionChange(inboxConfig: InboxScrapeConfiguration) {
    this.connectAccountInputSubject$.next(
      new ConnectLinkedInAccountInputDto({
        ...this.connectAccountInputSubject$.value,
        inboxScrapeConfiguration: inboxConfig,
      }),
    );
  }

  public clearSingleAccountInput(): void {
    this.customProxyService.updateCustomProxyState(undefined);
    this.connectAccountInputSubject$.next(new ConnectLinkedInAccountInputDto());
  }

  public setUsernameOnConnectAccountInput(username: string) {
    this.connectAccountInputSubject$.next(
      new ConnectLinkedInAccountInputDto({
        ...this.connectAccountInputSubject$.value,
        username: username,
      }),
    );
  }

  public setPasswordOnConnectAccountInput(password: string) {
    this.connectAccountInputSubject$.next(
      new ConnectLinkedInAccountInputDto({
        ...this.connectAccountInputSubject$.value,
        password: password,
      }),
    );
  }

  public setPinForSelectedAccountAuth(pin: string) {
    this.linkedInAccountAuthPinsSubject$.next({
      ...this.linkedInAccountAuthPinsSubject$.value,
      [this.selectedAccountAuthSubject$.value.linkedInAccountId]: pin,
    });
  }

  private clearSlotFilters(): void {
    this.pageNumberSubject$.next(0);
    this.searchStringSubject$.next('');
    this.statusFilterSubject$.next(undefined);
    this.isActiveFilterSubject$.next(undefined);
  }

  private checkForMultipleRecruiterPlans(
    accountId: number,
    recruiterPlans: RecruiterContractDto[],
  ) {
    const data = {
      accountId: accountId,
      recruiterPlans: recruiterPlans,
    } as RecruiterPlanSelectionDialogData;

    if (recruiterPlans && recruiterPlans.length > 1 && accountId) {
      this.openDialogSubject$.next({
        component: RecruiterSubscriptionsDialogComponent,
        config: {
          disableClose: true,
          hasBackdrop: true,
          data: data,
        },
      });
    }
  }
}
