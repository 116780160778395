import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { LinkedInAccountSlotDto } from '@shared/service-proxies/service-proxies';
import { AppLinkedInChallengeType } from '@shared/AppEnums';
import { AccountStatus } from '@app/linkedin-accounts/linkedin-accounts.component';

@Pipe({
  name: 'accountStatusPipe',
  pure: true,
})
export class AccountStatusPipe implements PipeTransform {
  transform(slot: LinkedInAccountSlotDto): AccountStatus {
    if (!slot.isActive) {
      return 'SLOT_INACTIVE';
    }

    if (slot.linkedInAccount.challengeType !== null) {
      // @ts-ignore
      if (
        slot.linkedInAccount.challengeType === AppLinkedInChallengeType.PIN &&
        moment(slot.linkedInAccount.pinAuthSessionStart).isAfter(moment().subtract(15, 'minutes'))
      ) {
        return 'PIN_VERIFICATION';
      }

      if (
        slot.linkedInAccount.challengeType === AppLinkedInChallengeType.LINKEDIN_APP &&
        moment(slot.linkedInAccount.pinAuthSessionStart).isAfter(moment().subtract(15, 'minutes'))
      ) {
        return 'APPLICATION_VERIFICATION';
      }

      if (
        slot.linkedInAccount.challengeType === AppLinkedInChallengeType.AUTHENTICATOR &&
        slot.linkedInAccount.pinAuthSessionStart?.isAfter(moment().subtract(15, 'minutes'))
      ) {
        return 'AUTHENTICATOR_VERIFICATION';
      }

      return 'UNHANDLED_CHALLENGE';
    }

    if (!slot.linkedInAccount.authIsValid) {
      return 'INVALID_AUTH';
    }

    if (!slot.linkedInAccount.linkedInUserProfile) {
      return 'PROFILE_SYNCING';
    }

    return slot.linkedInAccount?.isActive ? 'ACTIVE' : 'INACTIVE';
  }
}
