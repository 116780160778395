import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, combineLatest } from 'rxjs';
import { finalize, map, shareReplay, startWith, switchMap, take, tap } from 'rxjs/operators';
import {
  UserSearchResultDto,
  LinkedInUserListDto,
  LinkedInUserListServiceProxy,
  LinkedInSearchServiceProxy,
  ExportCrmListContactsDto,
  CrmExportLeadProperties,
  DeleteUsersFromListDto,
  LinkedInSearchOutputDto,
  CompanySearchResultDto,
} from '@shared/service-proxies/service-proxies';
import { UserInterfaceService } from '@shared/user-interface/user-interface.service';
import { AppLinkedInUserListStatus } from '@shared/AppEnums';
import {
  CrmExportType,
  ExportToCrmComponent,
} from '@shared/components/dialogs/export-to-crm/export-to-crm.component';
import { ImportCSVDialogComponent } from '@shared/components/dialogs/import-csv-dialog/import-csv-dialog.component';
import { RenameUserListDialogComponent } from '@app/linkedin-user-lists/list-dialogs/rename-user-list-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ListFilesServiceProxy } from '@shared/service-proxies/file-download-proxies';
import * as FileSaver from 'file-saver';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class LinkedinListsService {
  private readonly calculatedHeightSubject$ = new ReplaySubject<number>(0);
  public readonly calculatedHeightPx$: Observable<number> =
    this.calculatedHeightSubject$.asObservable();

  private readonly refreshTriggerSubject$ = new BehaviorSubject<void>(undefined);
  public readonly refreshTrigger$ = this.refreshTriggerSubject$.asObservable();

  private readonly refreshTriggerSubjectForLeadsCompanies$ = new BehaviorSubject<void>(undefined);
  public readonly refreshTriggerLeadsCompanies$ =
    this.refreshTriggerSubjectForLeadsCompanies$.asObservable();

  public refreshTriggerForLeadsCompanies() {
    this.refreshTriggerSubjectForLeadsCompanies$.next();
  }
  private readonly isLoadingSubject$ = new BehaviorSubject<boolean>(true);
  public readonly isLoading$: Observable<boolean> = this.isLoadingSubject$
    .asObservable()
    .pipe(shareReplay({ bufferSize: 1, refCount: true }));

  public updatePageNumber(pageNumber: number): void {
    this.pageNumberSubject$.next(pageNumber);
  }

  public goToNextPage(): void {
    const currentPageNumber = this.pageNumberSubject$.getValue();
    this.pageNumberSubject$.next(currentPageNumber + 1);
  }

  private readonly pageNumberSubject$ = new BehaviorSubject<number>(0);
  private readonly oldPageNumberSubject$ = new BehaviorSubject<number>(0);
  public readonly pageNumber$ = this.pageNumberSubject$.asObservable();

  private readonly isLoadedLastPageSubject$ = new BehaviorSubject<boolean>(true);
  public readonly isLoadedLastPage$ = this.isLoadedLastPageSubject$.asObservable();

  private readonly listIdSubject$ = new BehaviorSubject<number>(0);
  public readonly listId$ = this.listIdSubject$.asObservable();

  public updateListId(listId: number): void {
    this.listIdSubject$.next(listId);
  }

  private readonly listTypeSubject$ = new BehaviorSubject<number>(0);
  public readonly listType$ = this.listTypeSubject$.asObservable();

  public updateListType(listType: number): void {
    this.listTypeSubject$.next(listType);
  }

  public updateListName(listName: string, listId: number): void {
    const currentList = this.listStateSubject$.getValue();
    currentList.name = listName;
    this.listStateSubject$.next(currentList);
    this.listIdSubject$.next(listId);
  }

  public resetLeadsState(): void {
    this.companyLeadsSubject$.next([]);
    this.leadsState$.next([]);
    this.updatePageNumber(0);
    this.setSearchTerm('');
  }

  public updateListSearchStatus(
    searchStatus: number,
    res: LinkedInSearchOutputDto,
    listId: number,
  ): void {
    const currentList = this.listStateSubject$.getValue();
    currentList.status = searchStatus;
    currentList.search = res;
    this.listStateSubject$.next(currentList);
    this.listIdSubject$.next(listId);
  }

  private readonly totalLeadsSubject$ = new BehaviorSubject<number>(0);
  public readonly totalLeads$ = this.totalLeadsSubject$.asObservable();
  public readonly totalLeadsValue = this.totalLeadsSubject$.value;

  private readonly listEmptySubject$ = new BehaviorSubject<boolean>(true);
  public readonly listEmpty$ = this.listEmptySubject$.asObservable();

  private readonly isExportInProgressSubject$ = new BehaviorSubject<boolean>(false);
  public readonly isExportInProgress$ = this.isExportInProgressSubject$.asObservable();

  private readonly stoppingSearchInProgressSubject$ = new BehaviorSubject<boolean>(false);
  public readonly stoppingSearchInProgress$ = this.stoppingSearchInProgressSubject$.asObservable();

  private readonly emailEnrichingInProgressSubject$ = new BehaviorSubject<boolean>(false);
  public readonly emailEnrichingInProgress$ = this.emailEnrichingInProgressSubject$.asObservable();

  private readonly searchStringSubject$ = new BehaviorSubject<string>('');
  public readonly searchString$: Observable<string> = this.searchStringSubject$.asObservable();
  private readonly previousSearchString$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public setSearchTerm(searchTerm: string): void {
    this.isLoadingSubject$.next(true);
    this.searchStringSubject$.next(searchTerm);
    this.updatePageNumber(0);
  }

  private readonly listStateSubject$: BehaviorSubject<LinkedInUserListDto> = new BehaviorSubject(
    undefined,
  );
  public readonly listState$: Observable<LinkedInUserListDto> =
    this.listStateSubject$.asObservable();

  public readonly list$: Observable<LinkedInUserListDto> = combineLatest([
    this.listId$,
    this.refreshTrigger$,
  ]).pipe(
    tap(() => this.isLoadingSubject$.next(true)),
    switchMap(([listId, _]) => {
      if (listId !== 0) {
        return this._linkedinUserListsService.get(listId).pipe(
          map((list) => {
            this.listStateSubject$.next(list);
            this.isLoadingSubject$.next(false);
            return list;
          }),
        );
      }
    }),
    tap(() => {
      this.calculatedHeightSubject$.next(this._uiService.calculateHeight());
    }),
    finalize(() => {
      this.isLoadingSubject$.next(false);
    }),
  );

  private readonly companyLeadsSubject$: BehaviorSubject<CompanySearchResultDto[]> =
    new BehaviorSubject([]);
  public readonly companyLeads$: Observable<CompanySearchResultDto[]> = combineLatest([
    this.pageNumber$,
    this.listId$,
    this.searchString$,
    this.refreshTriggerLeadsCompanies$,
  ]).pipe(
    tap(() => this.isLoadingSubject$.next(true)),
    switchMap(([pageNumber, listId, searchString, _]) => {
      if (searchString !== this.previousSearchString$.getValue()) {
        this.companyLeadsSubject$.next([]);
        this.previousSearchString$.next(searchString);
      }
      if (listId !== 0) {
        return this._linkedinUserListsService
          .getPaginatedLinkedInCompaniesFromList(listId, pageNumber, searchString)
          .pipe(
            map((leads) => {
              this.totalLeadsSubject$.next(leads.totalCount);
              leads.items.length > 0
                ? this.listEmptySubject$.next(false)
                : this.listEmptySubject$.next(true);
              const currentLeads = this.companyLeadsSubject$.getValue();
              const currentLeadIds = currentLeads.map((x) => x.id);
              const newLeads = leads.items.filter((x) => !currentLeadIds.includes(x.id));
              const allLeads = [...currentLeads, ...newLeads];
              this.companyLeadsSubject$.next(allLeads);
              this.isLoadingSubject$.next(false);
              return allLeads;
            }),
          );
      }
    }),
    tap(() => {
      this.calculatedHeightSubject$.next(this._uiService.calculateHeight());
    }),
    finalize(() => this.isLoadingSubject$.next(false)),
  );

  private readonly leadsState$: BehaviorSubject<UserSearchResultDto[]> = new BehaviorSubject([]);
  public readonly leads$: Observable<UserSearchResultDto[]> = combineLatest([
    this.pageNumber$,
    this.listId$,
    this.searchString$,
    this.refreshTriggerLeadsCompanies$,
  ]).pipe(
    switchMap(([pageNumber, listId, searchString]) => {
      if (searchString !== this.previousSearchString$.getValue()) {
        this.leadsState$.next([]);
        this.previousSearchString$.next(searchString);
      }
      // Avoid triggering loading animation when removing leads
      if (this.oldPageNumberSubject$.getValue() !== this.pageNumberSubject$.getValue()) {
        this.isLoadingSubject$.next(true);
      }

      return this._linkedinUserListsService
        .getPaginatedLinkedInUsersFromList(listId, pageNumber, searchString)
        .pipe(
          map((leads) => {
            leads.items.length > 0
              ? this.listEmptySubject$.next(false)
              : this.listEmptySubject$.next(true);
            const currentLeads = this.leadsState$.getValue();
            const currentLeadIds = currentLeads.map((x) => x.id);
            const newLeads = leads.items.filter((x) => !currentLeadIds.includes(x.id));
            const allLeads = [...currentLeads, ...newLeads];
            this.leadsState$.next(allLeads);
            this.isLoadingSubject$.next(false);
            this.totalLeadsSubject$.next(leads.totalCount);
            return allLeads.map((lead) => {
              lead.customUserFields.forEach((field) => {
                lead[field.name] = field.value;
              });
              return lead;
            });
          }),
        );
    }),
    tap(() => {
      this.calculatedHeightSubject$.next(this._uiService.calculateHeight());
    }),
    finalize(() => this.isLoadingSubject$.next(false)),
  );

  constructor(
    private _linkedinUserListsService: LinkedInUserListServiceProxy,
    private _uiService: UserInterfaceService,
    private _dialog: MatDialog,
    private _linkedinUserListFileService: ListFilesServiceProxy,
    private _linkedInSearchService: LinkedInSearchServiceProxy,
    private _toaster: MatSnackBar,
  ) {}

  searchByMethod(searchTerm: string | null): void {
    this.isLoadingSubject$.next(true);
    this.updatePageNumber(0);
    this.leadsState$.next([]);
    this.searchStringSubject$.next(searchTerm);
  }

  isListEmpty() {
    if (
      this.totalLeadsSubject$.getValue() == 0 &&
      this.listStateSubject$.getValue().status != AppLinkedInUserListStatus.QUEUED &&
      this.listStateSubject$.getValue().status != AppLinkedInUserListStatus.IN_PROGRESS
    ) {
      this.listEmptySubject$.next(true);
      return true;
    } else {
      this.listEmptySubject$.next(false);
      return false;
    }
  }

  onExportToCrmClick(): void {
    let exportType: CrmExportType = 'LIST';
    combineLatest([this.listId$, this.searchString$])
      .pipe(take(1))
      .subscribe(([listId, searchTerm]) => {
        let exportData: ExportCrmListContactsDto = new ExportCrmListContactsDto();
        exportData.listId = listId;
        exportData.searchTerm = searchTerm;
        exportData.crmInfoId = null;
        exportData.exportLeadProperties = new CrmExportLeadProperties();
        const dialogRef = this._dialog.open(ExportToCrmComponent, {
          data: {
            listExport: exportData,
            exportType: exportType,
          },
        });
        dialogRef.afterClosed().subscribe(() => {
          dialogRef.close();
        });
      });
  }

  onExportUsersClick() {
    this.isExportInProgressSubject$.next(true);
    const list = this.listStateSubject$.getValue();
    if (list) {
      const listId = list.id;
      this._linkedinUserListFileService
        .getExportedUsersFromList(listId)
        .pipe(
          finalize(() => {
            this.isExportInProgressSubject$.next(false);
          }),
        )
        .subscribe((data) => {
          let filename = list.name ? `${list.name}.csv` : 'export.csv';
          FileSaver.saveAs(data.body, filename);
        });
    }
  }

  removeLeads(selectedItems: any[], allSelected: boolean) {
    // listType 0 = leads, 1 = companies
    const listType = this.listTypeSubject$.getValue();

    const allIds: any[] =
      listType === 0
        ? this.leadsState$.getValue().map((item) => item.linkedInUserMemberId)
        : this.companyLeadsSubject$.getValue().map((item) => item.linkedInCompanyId);

    const selectedMemberIds = selectedItems.map((item) =>
      listType === 0 ? item.linkedInUserMemberId : item.linkedInCompanyId,
    );
    const difference = allIds.filter((x) => !selectedMemberIds.includes(x));
    const list = this.listStateSubject$.getValue();
    if (list) {
      abp.message.confirm(undefined, 'Are you sure you want to remove these leads?', (res) => {
        if (!res) return;
        const listId = list.id;
        this._linkedinUserListsService
          .deleteUsersFromList(
            new DeleteUsersFromListDto({
              listId: listId,
              allSelected: allSelected,
              memberIds: allSelected ? difference : selectedMemberIds,
            }),
          )
          .subscribe(() => {
            if (listType === 0) {
              const currentLeads = this.leadsState$.getValue();
              const updatedLeads = currentLeads.filter(
                (lead) => !selectedMemberIds.includes(lead.linkedInUserMemberId),
              );
              this.leadsState$.next(updatedLeads);
              this.totalLeadsSubject$.next(updatedLeads.length);
            } else {
              const currentLeads = this.companyLeadsSubject$.getValue();
              const updatedLeads = currentLeads.filter(
                (lead) => !selectedMemberIds.includes(lead.linkedInCompanyId),
              );
              this.companyLeadsSubject$.next(updatedLeads);
              this.totalLeadsSubject$.next(updatedLeads.length);
            }

            this.oldPageNumberSubject$.next(this.pageNumberSubject$.getValue());
            this.updatePageNumber(this.oldPageNumberSubject$.getValue());
          });
      });
    }
  }

  stopActiveSearch() {
    this.stoppingSearchInProgressSubject$.next(true);
    this._linkedInSearchService
      .cancelActiveSearch(this.listStateSubject$.getValue().search.id)
      .pipe(
        finalize(() => {
          this.stoppingSearchInProgressSubject$.next(false);
        }),
      )
      .subscribe((res) => {
        this._toaster.open(
          `Stopped importing prospects in '${this.listStateSubject$.getValue().name}'.`,
          undefined,
          {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 4000,
          },
        );
        this.updateListSearchStatus(
          AppLinkedInUserListStatus.PASSIVE,
          res,
          this.listIdSubject$.getValue(),
        );
      });
  }

  editListName() {
    const currentList = this.listStateSubject$.getValue();
    this._dialog
      .open(RenameUserListDialogComponent, {
        width: 'auto',
        maxHeight: '95vh',
        autoFocus: false,
        data: {
          list: currentList,
        },
      })
      .afterClosed()
      .subscribe((listName: string) => {
        if (listName) {
          this.updateListName(listName, currentList.id);
        }
      });
  }

  importFromCSV() {
    const list = this.listStateSubject$.getValue();
    if (list) {
      this._dialog
        .open(ImportCSVDialogComponent, {
          height: '85vh',
          width: '50vw',
          data: list.id,
          autoFocus: false,
        })
        .afterClosed()
        .subscribe((confirmed) => {
          if (confirmed) {
            this.refreshTriggerSubjectForLeadsCompanies$.next(); // force reload
          }
        });
    }
  }
}
