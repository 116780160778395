import { Pipe, PipeTransform } from '@angular/core';
import { AppCampaignStatus } from '@shared/AppEnums';

@Pipe({
  name: 'badgeStatusClass',
})
export class BadgeStatusClassPipe implements PipeTransform {
  transform(status: AppCampaignStatus): string {
    return BADGE_STATUS_CLASS_RECORD[status as string] || 'bg-warn-muted text-secondary';
  }
}

export const BADGE_STATUS_CLASS_RECORD = {
  danger: 'bg-warn-muted text-warn',
  warning: 'bg-amber-100 text-amber-500',
  queued: 'bg-orange-100 text-orange-500',
  success: 'bg-success-muted text-success',
  progress: 'bg-primary-muted text-primary',
  iddle: 'bg-warn-muted text-secondary',
};
