import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import {
  AssignProfileTagsByFilterDto,
  AssignProfileTagsDto,
  CreateLinkedInProfileTagDto,
  LinkedInProfileTagServiceProxy,
  LinkedInProfileTagDto,
  LnkLinkedInProfileTagDto,
  UpdateProfileTagDto,
} from '@shared/service-proxies/service-proxies';
import { InboxService } from './inbox.service';

@Injectable()
export class ProfileTagService {
  private _tagsSubject: BehaviorSubject<LinkedInProfileTagDto[]> = new BehaviorSubject([]);
  public tags$: Observable<LinkedInProfileTagDto[]> = this._tagsSubject.asObservable();
  private _loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public loading$: Observable<boolean> = this._loadingSubject.asObservable();

  constructor(
    private _tagService: LinkedInProfileTagServiceProxy,
    private _inboxService: InboxService,
  ) {
    this.loadTags();
  }

  private loadTags() {
    this._loadingSubject.next(true);
    this._tagService
      .getTags(undefined)
      .pipe(finalize(() => this._loadingSubject.next(false)))
      .subscribe(
        (tags) => {
          this._tagsSubject.next(tags);
        },
        (error) => {
          console.error('Failed to load tags', error);
          this._loadingSubject.next(false);
        },
      );
  }

  public createTag(tag: CreateLinkedInProfileTagDto): Promise<LinkedInProfileTagDto> {
    this._loadingSubject.next(true);

    return new Promise((resolve, reject) => {
      this._tagService
        .getTags(undefined)
        .pipe(
          take(1),
          finalize(() => this._loadingSubject.next(false)),
        )
        .subscribe(
          (tags: LinkedInProfileTagDto[]) => {
            const duplicateTag = tags.find(
              (existingTag) => existingTag.displayName === tag.displayName,
            );
            if (duplicateTag) {
              reject(new Error('Tag already exists'));
            } else {
              this._tagService
                .createTag(tag)
                .pipe(finalize(() => this._loadingSubject.next(false)))
                .subscribe(
                  (newTag: LinkedInProfileTagDto) => {
                    const currentTags = this._tagsSubject.getValue();
                    this._tagsSubject.next([...currentTags, newTag]);

                    resolve(newTag);
                  },
                  (error) => {
                    console.error('Failed to create tag', error);
                    this._loadingSubject.next(false);
                    reject(error);
                  },
                );
            }
          },
          (error) => {
            this._loadingSubject.next(false);
            reject(error);
          },
        );
    });
  }

  public updateTag(tag: UpdateProfileTagDto): void {
    this._tagService.updateTag(tag).subscribe(
      () => {
        const currentTags = this._tagsSubject.getValue();
        const updatedTags = currentTags.map((t) =>
          t.id === tag.tagId
            ? ({
                ...t,
                colorHex: tag.colorHex,
                displayName: tag.displayName,
              } as LinkedInProfileTagDto)
            : t,
        );
        this._tagsSubject.next(updatedTags);

        const updatedTag = updatedTags.find((t) => t.id === tag.tagId);
        if (updatedTag) {
          this._inboxService.updateTag(updatedTag);
        }
      },
      (error) => {
        console.error('Failed to update tag', error);
      },
    );
  }

  public deleteTag(tagId: number): void {
    this._tagService.deleteTag(tagId).subscribe(
      () => {
        const currentTags = this._tagsSubject.getValue();
        const updatedTags = currentTags.filter((t) => t.id !== tagId);
        this._tagsSubject.next(updatedTags);
        this._inboxService.unAssignTagFromAllChatrooms(tagId);
      },
      (error) => {
        console.error('Failed to delete tag', error);
        throw error;
      },
    );
  }

  public assignTags(tagId: number, profileIds: string[]): Promise<LnkLinkedInProfileTagDto[]> {
    return new Promise<LnkLinkedInProfileTagDto[]>((resolve, reject) => {
      this._tagService
        .assignTags(
          new AssignProfileTagsDto({
            tagIds: [tagId],
            profileIds: profileIds,
          }),
        )
        .subscribe(
          (assignedTags: LnkLinkedInProfileTagDto[]) => {
            // attach tag to chatrooms
            this._inboxService.assignTagsToChatrooms(assignedTags);
            resolve(assignedTags);
          },
          (error) => {
            console.error('Failed to assign tags', error);
            reject(error);
          },
        );
    });
  }
  public assignTagsByFilters(
    tagId: number,
    excludeProfileIds: string[],
  ): Promise<LnkLinkedInProfileTagDto[]> {
    return new Promise<LnkLinkedInProfileTagDto[]>((resolve, reject) => {
      const input = new AssignProfileTagsByFilterDto({
        filters: this._inboxService.filters,
        tagIds: [tagId],
        excludeProfileIds: excludeProfileIds,
      });

      this._tagService.assignTagsByFilter(input).subscribe(
        (assignedTags: LnkLinkedInProfileTagDto[]) => {
          // attach tag to chatrooms
          this._inboxService.assignTagsToChatrooms(assignedTags);
          resolve(assignedTags);
        },
        (error) => {
          console.error('Failed to assign tags by filters', error);

          reject(error);
        },
      );
    });
  }

  public unAssignTags(tagId: number, profileIds: string[]): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this._tagService
        .unassignTags(
          new AssignProfileTagsDto({
            tagIds: [tagId],
            profileIds: profileIds,
          }),
        )
        .subscribe(
          () => {
            // detach tag from chatrooms
            this._inboxService.unAssignTagForProfiles(tagId, profileIds);
            resolve();
          },
          (error) => {
            console.error('Failed to unassign tags', error);
            reject(error);
          },
        );
    });
  }

  public unAssignTagsByFilters(tagId: number, excludeProfileIds: string[]): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const input = new AssignProfileTagsByFilterDto({
        filters: this._inboxService.filters,
        tagIds: [tagId],
        excludeProfileIds: excludeProfileIds,
      });

      this._tagService.unassignTagsByFilter(input).subscribe(
        () => {
          // detach tag from chatrooms
          this._inboxService.unAssignTagFromSpecificChatrooms(
            tagId,
            this._inboxService
              .chatRoomsValue()
              .filter((x) => !excludeProfileIds.find((z) => x.correspondentMemberId == z)),
          );
          resolve();
        },
        (error) => {
          console.error('Failed to unassign tags by filters', error);

          reject(error);
        },
      );
    });
  }
}
