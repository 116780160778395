import { Component, Input, OnInit } from '@angular/core';
import { ApexOptions } from 'ng-apexcharts';
import { LinkedInUserProfileSsiDto } from '@shared/service-proxies/service-proxies';
@Component({
  selector: 'ssi-pie-chart-component',
  templateUrl: './ssi-pie-chart.component.html',
  styleUrls: ['./ssi-pie-chart.component.scss'],
})
export class SSIPieChartComponent implements OnInit {
  @Input() chartOptions: ApexOptions;
  @Input() hasOverallInfo: boolean = false;
  @Input() infoSizeLarge: boolean = false;
  @Input() ssiData: LinkedInUserProfileSsiDto;
  constructor() {}

  ngOnInit(): void {}
}
