<div
  [ngClass]="{ 'flex-col': showSubscriptionName, 'flex-row': !showSubscriptionName }"
  class="gap-x-2">
  <div class="inline-flex items-center justify-start gap-x-1" *ngIf="showPremium">
    <mat-icon
      matTooltip="LinkedIn Premium"
      [ngClass]="['text-linkedin-premium', 'icon-size-' + (iconsSize === 'small' ? 4 : 5)]"
      svgIcon="custom:linkedin-premium"></mat-icon>
    <span *ngIf="showSubscriptionName" [ngClass]="{ 'text-sm': textSize === 'small' }">
      LinkedIn Premium
    </span>
  </div>
  <div class="inline-flex items-center justify-start gap-x-1" *ngIf="showNavigator">
    <mat-icon
      matTooltip="LinkedIn Sales Navigator"
      [ngClass]="[
        account.isValidNavigator && account.authIsValid
          ? 'text-linkedin-sales-navigator'
          : 'text-muted',
        'icon-size-' + (iconsSize === 'small' ? 4 : 5)
      ]"
      svgIcon="custom:linkedin-sales-navigator"></mat-icon>
    <span
      *ngIf="showSubscriptionName"
      [ngClass]="{
        'text-sm': textSize === 'small',
        'text-muted': !account.isValidNavigator && account.authIsValid
      }">
      Sales Navigator
    </span>
    <mat-icon
      *ngIf="!account.isValidNavigator && account.authIsValid"
      svgIcon="custom:warn"
      [matTooltip]="invalidNavigatorTooltip"
      class="icon-size-4 text-muted"></mat-icon>
  </div>
  <div class="inline-flex items-center justify-start gap-x-1" *ngIf="showRecruiter">
    <mat-icon
      matTooltip="LinkedIn Recruiter"
      [ngClass]="[
        account.isValidRecruiter && account.authIsValid ? 'text-linkedin-recruiter' : 'text-muted',
        'icon-size-' + (iconsSize === 'small' ? 4 : 5)
      ]"
      svgIcon="custom:linkedin-recruiter"></mat-icon>
    <span
      *ngIf="showSubscriptionName"
      [ngClass]="{
        'text-sm': textSize === 'small',
        'text-muted': !account.isValidRecruiter && account.authIsValid
      }">
      Recruiter
    </span>
    <mat-icon
      *ngIf="!account.isValidRecruiter && account.authIsValid"
      svgIcon="custom:warn"
      [matTooltip]="invalidRecruiterTooltip"
      class="icon-size-4 text-muted"></mat-icon>
  </div>

  <span class="font-medium text-base" *ngIf="showFreeAccountText">Free Account</span>
</div>
