<div class="container">
  <user-avatar
    [imageUrl]="profile?.imageUrl"
    [fullName]="profile?.fullName"
    [firstName]="profile?.firstName"
    [lastName]="profile?.lastName"
    [tiny]="tiny"></user-avatar>
  <user-display-name [profile]="profile" [altName]="altName"></user-display-name>
  <div class="flex justify-center items-center" *ngIf="showSubscription">
    <linkedin-user-profile-subscriptions [profile]="profile"></linkedin-user-profile-subscriptions>
  </div>
</div>
