<div class="text-center flex flex-col m-auto min-h-120 w-full content-center justify-items-center">
  <div class="flex flex-col mt-24 mb-24 items-center">
    <progress-spinner [diameter]="28" color="primary"></progress-spinner>
    <p class="text-dark text-3xl text-center font-bold mb-6 mt-10">Please wait</p>
    <p class="text-secondary text-lg text-center font-medium">
      We are connecting your LinkedIn account and setting everything up.
      <br />
      This process may take up to 4 minutes.
    </p>
  </div>
</div>
