import { mergeMap as _observableMergeMap, catchError as _observableCatch } from 'rxjs/operators';
import { Observable, throwError as _observableThrow, of as _observableOf } from 'rxjs';
import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { API_BASE_URL } from './service-proxies';

@Injectable()
export class ListFilesServiceProxy {
  private http: HttpClient;
  private baseUrl: string;
  protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

  constructor(
    @Inject(HttpClient) http: HttpClient,
    @Optional() @Inject(API_BASE_URL) baseUrl?: string,
  ) {
    this.http = http;
    this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : '';
  }

  /**
   * @param listId (optional)
   * @return Success
   */
  getExportedUsersFromList(listId: number | undefined): Observable<any> {
    let url_ = this.baseUrl + '/api/LinkedInUserList/GetExportedUsersFromList?';
    if (listId === null) throw new Error("The parameter 'listId' cannot be null.");
    else if (listId !== undefined) url_ += 'listId=' + encodeURIComponent('' + listId) + '&';
    url_ = url_.replace(/[?&]$/, '');

    let options_: any = {
      observe: 'response',
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({}),
    };

    return this.http.get(url_, options_);
  }

  getExportedLeadsFromCampaign(
    campaignId: number | undefined,
    searchTerm: string | undefined,
    linkedInAccountIds: Array<number> | undefined,
    dateFrom: Date | undefined,
    dateTo: Date | undefined,
  ): Observable<any> {
    let url_ = this.baseUrl + '/api/Dashboard/GetExportedLeadsFromCampaign?';
    if (campaignId === null) throw new Error("The parameter 'campaignId' cannot be null.");
    else if (campaignId !== undefined)
      url_ += 'campaignId=' + encodeURIComponent('' + campaignId) + '&';

    if (searchTerm !== undefined) url_ += 'searchTerm=' + encodeURIComponent('' + searchTerm) + '&';
    if (linkedInAccountIds !== undefined)
      linkedInAccountIds.forEach((item) => {
        url_ += 'linkedInAccountIds=' + encodeURIComponent('' + item) + '&';
      });
    if (dateFrom !== undefined)
      url_ += 'dateFrom=' + encodeURIComponent('' + dateFrom.toISOString()) + '&';
    if (dateTo !== undefined)
      url_ += 'dateTo=' + encodeURIComponent('' + dateTo.toISOString()) + '&';

    url_ = url_.replace(/[?&]$/, '');

    let options_: any = {
      observe: 'response',
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({}),
    };

    return this.http.get(url_, options_);
  }
}
