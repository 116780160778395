import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ConnectLinkedInAccountInputDto,
  InboxScrapeConfiguration,
  LinkedInAccountSlotDto,
  OwnProxyDto,
} from '@shared/service-proxies/service-proxies';
import { Observable } from 'rxjs';
import { AppLinkedInChallengeType } from '@shared/AppEnums';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { SecurityCodeComponent } from '@shared/components/security-code/security-code.component';
import { getInboxConfigurations } from './connect-account-consts';
import { WhiteLabelService } from '@app/white-label.service';
import { CustomProxyService } from './custom-proxy-service';
import { AppSessionService } from '@shared/session/app-session.service';
import { LinkedInAccountsService } from '@app/linkedin-accounts/linked-in-accounts.service';
import { MatDialogConfig } from '@node_modules/@angular/material/dialog';

@Component({
  selector: 'connect-account',
  templateUrl: 'connect-account.component.html',
})
export class ConnectAccountComponent {
  @ViewChild('securityCode', { static: false }) securityCode: SecurityCodeComponent;
  customClass: string = '';
  inboxConfigurations;
  @Output() onCloseDrawer: EventEmitter<any> = new EventEmitter<any>();
  @Output() accountLinked: EventEmitter<void> = new EventEmitter<void>();
  @Output() reConnectAccount: EventEmitter<void> = new EventEmitter<void>();
  @Input() isAddWidgetMode: boolean = false;
  @Input() connectedAccountEmail: string = '';
  isAgency: boolean = this.appSession.subscription?.subscriptionType?.isAgency;
  resendPinError: boolean = false;

  isCustomProxyRequired: boolean =
    this.appSession.subscription?.subscriptionType?.isCustomProxyRequired ?? false;
  companyName = this._whiteLabelService.companyName;
  isUsingHeyReachExtension: boolean = false;

  inboxScrapeConfigurationLabel: string;
  protected readonly AppLinkedInChallengeType = AppLinkedInChallengeType;
  protected selectedAccountAuth$: Observable<LinkedInAccountSlotDto> =
    this.linkedInAccountsService.selectedAccountAuth$;
  protected accountsWithIncorrectPin$: Observable<{ [key: number]: boolean }> =
    this.linkedInAccountsService.accountsWithIncorrectPin$;
  protected openDialog$: Observable<{ component: any; config: MatDialogConfig }> =
    this.linkedInAccountsService.openDialog$;
  protected connectAccountInput$: Observable<ConnectLinkedInAccountInputDto> =
    this.linkedInAccountsService.connectAccountInput$;
  protected isSingleLoginInProgress$: Observable<boolean> =
    this.linkedInAccountsService.isSingleLoginInProgress$;
  protected clearPinValue$: Observable<void> = this.linkedInAccountsService.clearPinValue$;
  protected isProcessingPin$: Observable<boolean> = this.linkedInAccountsService.isProcessingPin$;
  protected linkedInAccountAuthsPins$: Observable<{ [key: number]: string }> =
    this.linkedInAccountsService.linkedInAccountAuthPins$;

  constructor(
    private _toaster: MatSnackBar,
    private _dialog: MatDialog,
    public customProxyService: CustomProxyService,
    private _whiteLabelService: WhiteLabelService,
    private appSession: AppSessionService,
    private linkedInAccountsService: LinkedInAccountsService,
  ) {
    this.customProxyService.updateCustomProxyState(new OwnProxyDto());
    this.inboxConfigurations = getInboxConfigurations(this._whiteLabelService.companyName);
    this.inboxScrapeConfigurationLabel = `Select which conversations to load into ${_whiteLabelService.companyName}`;
    this.openDialog$.subscribe((res: { component: any; config: MatDialogConfig }) =>
      this._dialog.open(res.component, res.config),
    );
    this.clearPinValue$.subscribe(() => this.clearPinValue());
  }

  public get isWhiteLabel(): boolean {
    return this._whiteLabelService.notUs;
  }

  connectAccount() {
    this.linkedInAccountsService.connectAccount().subscribe();
  }

  onAccountLinked(): void {
    this._toaster.open('Account linked successfully!', undefined, {
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      duration: 3500,
    });
    this.isUsingHeyReachExtension = false;
    this.linkedInAccountsService.loadLinkedInSlots();
    this.onCloseDrawer.emit();
    this.clearSingleAccountInput();
    this.customProxyService.resetState();
  }

  clearSingleAccountInput(): void {
    this.linkedInAccountsService.clearSingleAccountInput();
  }

  verifyPin(): void {
    this.linkedInAccountsService.verifyPin().subscribe();
  }

  clearPinValue() {
    this.securityCode.resetPin();
  }

  public onVerifyPinClick(slot: LinkedInAccountSlotDto) {
    this.linkedInAccountsService.verifyPinClicked(slot);
  }

  toggleAddProxy() {
    this.resetProxyState();
    this.customProxyService.toggleCustomProxy();
  }

  resetProxyState() {
    this.customProxyService.resetState();
    this.customProxyService.resetProxyErrorMessage();
  }

  onReConnectAccount() {
    this.reConnectAccount.emit();
  }

  extensionConnectClicked(event: { linkedInAccountLoaded: boolean }) {
    this.isUsingHeyReachExtension = event.linkedInAccountLoaded;
  }

  resetInvalidPinMesssage() {
    this.linkedInAccountsService.setPinValidityForSelectedAccount(true);
  }

  selectedInboxOptionChange(inboxConfig: InboxScrapeConfiguration) {
    this.linkedInAccountsService.selectedInboxOptionChange(inboxConfig);
  }

  public usernameInputChange(username: string) {
    this.linkedInAccountsService.setUsernameOnConnectAccountInput(username);
  }

  public passwordInputChange(password: string) {
    this.linkedInAccountsService.setPasswordOnConnectAccountInput(password);
  }

  public pinChange(pin: string) {
    this.linkedInAccountsService.setPinForSelectedAccountAuth(pin);
  }
}
