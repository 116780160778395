import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppConsts } from '@shared/AppConsts';
import {
  ConnectLinkedInAccountWithCookiesInputDto,
  InboxScrapeConfiguration,
  LinkedInAccountServiceProxy,
  OwnProxyDto,
} from '@shared/service-proxies/service-proxies';
import { catchError, finalize } from 'rxjs/operators';
import { AppLinkedInAccountVerificationType } from '@shared/AppEnums';
import { CustomProxyService } from '@app/linkedin-accounts/connect-account/custom-proxy-service';
import { getInboxConfigurations } from '../connect-account-consts';
import { WhiteLabelService } from '@app/white-label.service';
import { AppSessionService } from '@shared/session/app-session.service';
import { InterceptorDisabler } from '@shared/interceptors/disable-interceptor';
import { ErrorSnackbarComponent } from '@shared/components/error-snackbar/error-snackbar.component';
import { EMPTY } from '@node_modules/rxjs';

declare var window: any;

@Component({
  selector: 'connect-account-extension',
  templateUrl: 'connect-account-extension.component.html',
})
export class ConnectAccountExtensionComponent implements OnInit, OnDestroy {
  accountProfile: any;
  accountAlreadyConnected: boolean = false;
  hasExtension: boolean = false;
  detectExtensionInterval: any;
  isLoadingAccountProfile: boolean = false;
  companyName = this._whiteLabelService.companyName;
  isExtensionConnecting = false;
  inboxConfigurations;
  isAgency: boolean = this.appSession.subscription?.subscriptionType?.isAgency;

  public verificationType: number = AppLinkedInAccountVerificationType.NONE;

  @Output() accountLinkedFromExtension: EventEmitter<void> = new EventEmitter<void>();
  @Output() onExtensionConnectClick: EventEmitter<{ linkedInAccountLoaded: boolean }> =
    new EventEmitter();

  inboxScrapeConfigurationLabel: string;

  constructor(
    private _linkedinAccountService: LinkedInAccountServiceProxy,
    private _toaster: MatSnackBar,
    public _customProxyService: CustomProxyService,
    private _whiteLabelService: WhiteLabelService,
    private appSession: AppSessionService,
    private disabler: InterceptorDisabler,
    private toaster: MatSnackBar,
  ) {
    this._customProxyService.updateCustomProxyState(new OwnProxyDto());
    this.inboxConfigurations = getInboxConfigurations(this._whiteLabelService.companyName);
    this.inboxScrapeConfigurationLabel = `Select which conversations to load into ${this._whiteLabelService.companyName}`;
  }

  ngOnInit(): void {
    this.detectHeyreachWidget();
    this.detectExtensionInterval = setInterval(() => this.detectHeyreachWidget(), 1000);
  }

  ngOnDestroy(): void {
    if (this.detectExtensionInterval) {
      clearInterval(this.detectExtensionInterval);
    }
  }

  changeLinkedInAccount() {
    window.chrome.runtime?.sendMessage(AppConsts.extensionId, 'changeLiAccount', () => {});
  }

  loginWithCookies() {
    if (!this.accountProfile) {
      return;
    }

    this.disabler.disable();
    this.isExtensionConnecting = true;
    this._linkedinAccountService
      .createLinkedInAccountFromCookies({
        memberId: this.accountProfile.memberId,
        cookies: this.accountProfile.cookies,
        customProxy: this._customProxyService.proxyInput,
        inboxScrapeConfiguration: this.accountProfile.inboxScrapeConfiguration,
      } as ConnectLinkedInAccountWithCookiesInputDto)
      .pipe(
        finalize(() => {
          this.isExtensionConnecting = false;
        }),
        catchError((err) => {
          let errorString: string;
          try {
            errorString =
              JSON.parse(err.response)?.error?.message ||
              'An error occurred while trying to connect the account.';
          } catch (e) {
            // there is a parsing error when we get a 504 error response
            errorString = 'An error occurred while trying to connect the account.';
          }

          const snackbar = this.toaster.openFromComponent(ErrorSnackbarComponent, {
            duration: 5000,
            panelClass: 'error-snackbar',
          });
          snackbar.instance.message = errorString;
          throw EMPTY;
        }),
      )
      .subscribe(() => {
        this.resetProfileAndFields();
        this.accountLinkedFromExtension.emit();
      });
  }

  detectHeyreachWidget(): void {
    // Check if the user has installed the extension
    if (!window.chrome.runtime) {
      this.hasExtension = false;
    }

    window.chrome.runtime?.sendMessage(AppConsts.extensionId, 'version', (res) => {
      this.hasExtension = !!res;
    });
  }

  openExtensionInStore(): void {
    window.open(AppConsts.extensionDownloadUrl, '_blank');
  }

  openLoginConfirmation() {
    if (this.hasExtension) {
      this.isLoadingAccountProfile = true;
      window.chrome.runtime?.sendMessage(AppConsts.extensionId, 'getLinkedInProfile', (res) => {
        if (res.success) {
          this._linkedinAccountService
            .getValidLinkedInAccountsForTenant()
            .pipe(
              finalize(() => {
                this.isLoadingAccountProfile = false;
              }),
            )
            .subscribe((res1) => {
              this.accountAlreadyConnected = res1.some(
                (x) => x.linkedInUserProfile?.id === res.result?.memberId,
              );
              this.accountProfile = res.result;
              this.onExtensionConnectClick.emit({ linkedInAccountLoaded: true });
            });
        } else if (res.errorStatus == 401 || res.errorStatus == 403) {
          window.open('https://linkedin.com', '_blank');
          this.isLoadingAccountProfile = false;
          this.onExtensionConnectClick.emit({ linkedInAccountLoaded: false });
        } else {
          this.isLoadingAccountProfile = false;
          this.onExtensionConnectClick.emit({ linkedInAccountLoaded: false });
          this._toaster.open(
            res.errorMessage || 'Something went wrong please try again',
            undefined,
            {
              horizontalPosition: 'center',
              verticalPosition: 'bottom',
              duration: 3500,
            },
          );
        }
      });
    }
  }

  resetProfileAndFields() {
    this.accountProfile = undefined;
    this.accountAlreadyConnected = false;
  }

  toggleAddProxy() {
    this.resetProxyState();
    this._customProxyService.toggleCustomProxy();
  }

  loginWithLinkedIn() {
    this.resetProxyState();
    this.resetProfileAndFields();
    this.onExtensionConnectClick.emit({ linkedInAccountLoaded: false });
  }

  resetProxyState() {
    this._customProxyService.resetState();
    this._customProxyService.resetProxyErrorMessage();
  }

  selectedInboxOptionChange(inboxConfig: InboxScrapeConfiguration) {
    this.accountProfile.inboxScrapeConfiguration = inboxConfig;
    this.accountProfile = { ...this.accountProfile, inboxScrapeConfiguration: inboxConfig };
  }
}
