import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  CampaignDto,
  CampaignServiceProxy,
  CreateCampaignDto,
} from '@shared/service-proxies/service-proxies';
import { finalize } from 'rxjs/operators';

export type CampaignAction = 'DISCARDED' | 'CANCELED' | 'PAUSED' | 'RESUMED';

export interface CampaignActionPerformedData {
  campaignId: number;
  action: CampaignAction;
}

@Injectable({
  providedIn: 'root',
})
export class CampaignActionsService {
  private campaignsWhereThereIsAnOperationInProgressSubject$: BehaviorSubject<number[]> =
    new BehaviorSubject<number[]>([]);
  public campaignsWhereThereIsAnOperationInProgress$: Observable<number[]> =
    this.campaignsWhereThereIsAnOperationInProgressSubject$.asObservable();

  private onCampaignActionPerformedSubject$: BehaviorSubject<CampaignActionPerformedData> =
    new BehaviorSubject<CampaignActionPerformedData>(undefined);
  public onCampaignActionPerformed$: Observable<CampaignActionPerformedData> =
    this.onCampaignActionPerformedSubject$.asObservable();

  private isCampaignCreationInProgressSubject$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isCampaignCreationInProgress$: Observable<boolean> =
    this.isCampaignCreationInProgressSubject$.asObservable();

  private onCampaignCreatedSubject$: BehaviorSubject<number> = new BehaviorSubject<number>(
    undefined,
  );
  public onCampaignCreated$: Observable<number> = this.onCampaignCreatedSubject$.asObservable();

  constructor(private _campaignsService: CampaignServiceProxy) {}

  public discard(campaignId: number): void {
    this.campaignsWhereThereIsAnOperationInProgressSubject$.next([
      ...this.campaignsWhereThereIsAnOperationInProgressSubject$.value,
      campaignId,
    ]);
    this._campaignsService
      .discard(campaignId)
      .pipe(
        finalize(() => {
          this.campaignsWhereThereIsAnOperationInProgressSubject$.next(
            this.campaignsWhereThereIsAnOperationInProgressSubject$.value.filter(
              (id) => id !== campaignId,
            ),
          );
        }),
      )
      .subscribe(() => {
        this.onCampaignActionPerformedSubject$.next({
          campaignId: campaignId,
          action: 'DISCARDED',
        });
      });
  }

  public cancel(campaignId: number): void {
    this.campaignsWhereThereIsAnOperationInProgressSubject$.next([
      ...this.campaignsWhereThereIsAnOperationInProgressSubject$.value,
      campaignId,
    ]);
    this._campaignsService
      .cancelCampaign(campaignId)
      .pipe(
        finalize(() => {
          this.campaignsWhereThereIsAnOperationInProgressSubject$.next(
            this.campaignsWhereThereIsAnOperationInProgressSubject$.value.filter(
              (id) => id !== campaignId,
            ),
          );
        }),
      )
      .subscribe(() => {
        this.onCampaignActionPerformedSubject$.next({ campaignId: campaignId, action: 'CANCELED' });
      });
  }

  public pause(campaignId: number): void {
    this.campaignsWhereThereIsAnOperationInProgressSubject$.next([
      ...this.campaignsWhereThereIsAnOperationInProgressSubject$.value,
      campaignId,
    ]);
    this._campaignsService
      .pause(campaignId)
      .pipe(
        finalize(() => {
          this.campaignsWhereThereIsAnOperationInProgressSubject$.next(
            this.campaignsWhereThereIsAnOperationInProgressSubject$.value.filter(
              (id) => id !== campaignId,
            ),
          );
        }),
      )
      .subscribe(() => {
        this.onCampaignActionPerformedSubject$.next({ campaignId: campaignId, action: 'PAUSED' });
      });
  }

  public resume(campaignId: number) {
    this.campaignsWhereThereIsAnOperationInProgressSubject$.next([
      ...this.campaignsWhereThereIsAnOperationInProgressSubject$.value,
      campaignId,
    ]);
    this._campaignsService
      .resume(campaignId)
      .pipe(
        finalize(() => {
          this.campaignsWhereThereIsAnOperationInProgressSubject$.next(
            this.campaignsWhereThereIsAnOperationInProgressSubject$.value.filter(
              (id) => id !== campaignId,
            ),
          );
        }),
      )
      .subscribe(() => {
        this.onCampaignActionPerformedSubject$.next({ campaignId: campaignId, action: 'RESUMED' });
      });
  }

  public createNewCampaign(name: string) {
    this.isCampaignCreationInProgressSubject$.next(true);
    this._campaignsService
      .createDraft(new CreateCampaignDto({ name: name }))
      .pipe(
        finalize(() => {
          this.isCampaignCreationInProgressSubject$.next(false);
        }),
      )
      .subscribe((id) => {
        this.onCampaignCreatedSubject$.next(id);
        setTimeout(() => this.onCampaignCreatedSubject$.next(undefined), 0);
      });
  }
}
