<div id="main-header-container" class="bg-gray-100">
  <nav
    class="main-header z-0 pt-4 py-6 pl-1 navbar navbar-expand border-none bg-transparent navbar-light">
    <div class="flex flex-col flex-auto">
      <div class="flex-0 sm:items-center mr-6 ml-6">
        <div class="flex items-center justify-between w-full">
          <div class="flex items-center">
            <button
              *ngIf="urlService.backActionUrl.length"
              class="h-8 min-h-8 w-8 min-w-4 mr-1 -ml-2"
              heyreachButton
              buttonType="icon"
              (click)="navigateBack()">
              <mat-icon
                class="icon-size-4 text-primary"
                svgIcon="heroicons_solid:header-arrow-left"></mat-icon>
            </button>
            <div
              class="text-2xl font-bold flex text-dark tracking-normal items-start justify-start">
              {{ urlService.pageName }}
            </div>
            <button
              *ngIf="urlService.customIconName !== ''"
              class="mr-1"
              heyreachButton
              buttonType="icon"
              (click)="customIconAction(urlService.customActionName)">
              <mat-icon class="icon-size-4" [svgIcon]="urlService.customIconName"></mat-icon>
            </button>
          </div>

          <div class="flex items-start">
            <ng-container *ngIf="!notUs">
              <div class="flex items-center">
                <button
                  heyreachButton
                  buttonType="primary"
                  *ngIf="isAdmin"
                  routerLink="/admin"
                  class="mr-5">
                  Administration
                </button>

                <button
                  heyreachButton
                  buttonType="pale"
                  *ngIf="canReportBug"
                  class="hover:bg-pale-muted"
                  (click)="reportBug()">
                  <mat-icon class="icon-size-4.5" svgIcon="heroicons_outline:info"></mat-icon>
                  <span class="ml-2 text-secondary">Report a bug</span>
                </button>
                <button
                  *ngIf="urlService.helpLink.length"
                  heyreachButton
                  buttonType="pale"
                  class="ml-5 hover:bg-pale-muted"
                  (click)="showHelp()">
                  <mat-icon class="icon-size-4.5" svgIcon="heroicons_outline:question"></mat-icon>
                  <span class="ml-2 text-secondary">Help</span>
                </button>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </nav>
</div>
