<div class="relative h-10" [ngClass]="customClass">
  <button
    [attr.data-dropdown-toggle]="dropdownId"
    (click)="toggleDropdown()"
    type="button"
    [ngClass]="{
      'flex flex-auto items-center h-11 rounded-lg border-2 border-pale pt-1 px-4': isMyLeads,
      'relative inline-flex w-full justify-between items-center gap-x-1.5 rounded-md bg-white px-2 py-1 text-base font-semibold text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 z-10':
        !isMyLeads
    }"
    [attr.aria-expanded]="isDropdownOpen.toString()"
    [attr.aria-haspopup]="true">
    <div class="flex items-center w-full">
      <ng-container *ngIf="selectedItems.length > 0; else placeholderTmplRef">
        <div class="flex items-center flex-grow">
          <mat-icon
            *ngIf="selectedItems[0]?.icon"
            [svgIcon]="selectedItems[0].icon"
            class="icon-size-5 text-secondary mr-2"></mat-icon>
          <span class="text-gray-500 text-base font-medium pl-2">
            {{ selectedItems[0]?.label }}
          </span>
        </div>
      </ng-container>
      <ng-template #placeholderTmplRef>
        <div class="flex items-center flex-grow">
          <span class="text-gray-500 text-base font-medium pl-2">
            {{ placeholder }}
          </span>
        </div>
      </ng-template>
      <div class="flex items-center h-8 w-8 text-gray-500" aria-hidden="true">
        <img
          src="assets/icons/sidebar-icons/arrow-icon.svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true" />
      </div>
    </div>
  </button>

  <div
    [id]="dropdownId"
    class="absolute top-full left-0 z-20 w-full bg-white rounded-lg shadow dark:bg-gray-700 overflow-y-auto max-h-48 min-w-full"
    *ngIf="isDropdownOpen">
    <!-- Search input -->
    <div class="p-2" *ngIf="showSearchField">
      <app-search-field
        [id]="'searchField_' + dropdownId"
        [placeholder]="'Search...'"
        [ariaLabel]="'Search items'"
        [debounceTime]="300"
        (filter)="handleSearchFilter($event)"></app-search-field>
    </div>
    <!-- List of items -->
    <ul
      class="p-3 space-y-1 text-base text-gray-400 dark:text-gray-300"
      [attr.aria-labelledby]="dropdownId + 'Button'">
      <li *ngFor="let item of filteredCheckboxItems; trackBy: trackById">
        <div class="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
          <ng-container *ngIf="singleSelect; else multiSelectTmpRef">
            <label
              [for]="item.id"
              class="w-full pl-2 mt-2 text-base font-medium text-gray-900 dark:text-gray-300 text-nowrap"
              (click)="selectItem(item)">
              <mat-icon
                *ngIf="item.icon"
                [svgIcon]="item.icon"
                class="icon-size-5 text-secondary mr-2"></mat-icon>
              {{ item.label }}
            </label>
          </ng-container>
          <ng-template #multiSelectTmpRef>
            <input
              [id]="item.id"
              type="checkbox"
              [checked]="item.checked"
              (change)="selectItem(item)"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
            <label
              [for]="item.id"
              class="w-full pl-2 ms-2 text-base font-medium text-gray-900 dark:text-gray-300">
              <mat-icon
                *ngIf="item.icon"
                [svgIcon]="item.icon"
                class="icon-size-5 text-secondary mr-2"></mat-icon>
              {{ item.label }}
            </label>
          </ng-template>
        </div>
      </li>
    </ul>
  </div>
</div>
