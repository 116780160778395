import { Injectable, OnDestroy } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { CampaignDto, CampaignServiceProxy } from '@shared/service-proxies/service-proxies';
import { BehaviorSubject, Observable, Subject, combineLatest, forkJoin } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { CampaignActionsService } from './campaign-actions.service';
import { AppCampaignStatus } from '@shared/AppEnums';
import { LinkedInAccountSelectionService } from '@shared/components/linkedin/select-account/linkedin-account-selection.service';

@Injectable({
  providedIn: 'root',
})
export class ViewCampaignsService implements OnDestroy {
  private areCampaignsLoadingSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );
  public areCampaignsLoading$: Observable<boolean> =
    this.areCampaignsLoadingSubject$.asObservable();

  private campaignsSubject$: BehaviorSubject<CampaignDto[]> = new BehaviorSubject<CampaignDto[]>(
    [],
  );
  public campaigns$: Observable<CampaignDto[]> = this.campaignsSubject$.asObservable();

  private pageNumberSubject$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public pageNumber$: Observable<number> = this.pageNumberSubject$.asObservable();

  private campaignStatusFilterSubject$: BehaviorSubject<number> = new BehaviorSubject<number>(-1);
  public campaignStatusFilter$: Observable<number> =
    this.campaignStatusFilterSubject$.asObservable();

  private searchKeywordSubject$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public searchKeyword$: Observable<string> = this.searchKeywordSubject$.asObservable();

  private totalCampaignsSubject$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public totalCampaigns$: Observable<number> = this.totalCampaignsSubject$.asObservable();

  private destroy$: Subject<void> = new Subject<void>();

  private selectedLinkedInAccountIds: number[] = [];

  private selectedCampaignsSubject$: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);
  public selectedCampaigns$: Observable<number[]> = this.selectedCampaignsSubject$.asObservable();

  private storedCampaignsSubject$: BehaviorSubject<CampaignDto[]> = new BehaviorSubject<
    CampaignDto[]
  >([]);
  public storedCampaigns$: Observable<CampaignDto[]> = this.storedCampaignsSubject$.asObservable();

  constructor(
    private _campaignService: CampaignServiceProxy,
    private _campaignActionsService: CampaignActionsService,
    private _linkedInAccountSelectionService: LinkedInAccountSelectionService,
  ) {
    this.initializeService();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initializeService(): void {
    const linkedInAccountIds$ =
      this._linkedInAccountSelectionService.selectedLinkedInAccounts$.pipe(
        map((accounts) => accounts.map((x) => x.id)),
        distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
        tap((selectedLinkedInAccountIds) => {
          this.selectedLinkedInAccountIds = selectedLinkedInAccountIds;
        }),
        takeUntil(this.destroy$),
      );

    combineLatest([
      this.selectedCampaignsSubject$.pipe(
        filter((value) => value.length > 0),
        takeUntil(this.destroy$),
      ),
      linkedInAccountIds$,
    ]).subscribe(() => {
      this.getCampaignsWhenSelectedFilterActive();
    });

    combineLatest([
      this.selectedCampaignsSubject$.pipe(
        filter(() => this.selectedCampaignsSubject$.value.length == 0),
        takeUntil(this.destroy$),
      ),
      linkedInAccountIds$.pipe(
        filter(() => this.selectedCampaignsSubject$.value.length == 0),
        tap(() => {
          this.campaignsSubject$.next([]);
          this.pageNumberSubject$.next(0);
        }),
        takeUntil(this.destroy$),
      ),
      this.campaignStatusFilterSubject$.pipe(
        distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
        tap(() => {
          this.campaignsSubject$.next([]);
          this.pageNumberSubject$.next(0);
        }),
        takeUntil(this.destroy$),
      ),
      this.searchKeyword$.pipe(
        debounceTime(AppConsts.searchInputDebounceTimeMillis),
        distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
        tap(() => {
          this.campaignsSubject$.next([]);
          this.pageNumberSubject$.next(0);
        }),
        takeUntil(this.destroy$),
      ),
      this.pageNumberSubject$.pipe(
        distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
        takeUntil(this.destroy$),
      ),
    ])
      .pipe(
        filter(() => this.selectedCampaignsSubject$.value.length === 0),
        switchMap(
          ([
            selectedCampaignIds,
            selectedLinkedInAccountIds,
            campaignStatusFilter,
            searchKeyword,
            pageNumber,
          ]) => {
            this.areCampaignsLoadingSubject$.next(true);
            return this._campaignService
              .getAll(
                pageNumber,
                searchKeyword,
                campaignStatusFilter == -1 ? undefined : [campaignStatusFilter],
                selectedLinkedInAccountIds,
              )
              .pipe(
                finalize(() => {
                  this.areCampaignsLoadingSubject$.next(false);
                }),
              );
          },
        ),
        takeUntil(this.destroy$),
      )
      .subscribe((res) => {
        this.totalCampaignsSubject$.next(res.totalCount);
        this.campaignsSubject$.next(res.items);

        const currentCampaigns = this.storedCampaignsSubject$.value;
        const newCampaigns = res.items.filter((newCampaign) => {
          return !currentCampaigns.some(
            (existingCampaign) => existingCampaign.id === newCampaign.id,
          );
        });
        this.storedCampaignsSubject$.next([...currentCampaigns, ...newCampaigns]);
      });

    this._campaignActionsService.onCampaignActionPerformed$
      .pipe(takeUntil(this.destroy$))
      .subscribe((actionData) => {
        const campaignIndex = this.campaignsSubject$.value.findIndex(
          (x) => x.id === actionData.campaignId,
        );
        if (campaignIndex === -1) {
          return;
        }

        const updatedCampaigns = [...this.campaignsSubject$.value];

        switch (actionData.action) {
          case 'DISCARDED':
            updatedCampaigns.splice(campaignIndex, 1);
            break;
          case 'CANCELED':
            updatedCampaigns[campaignIndex].status = AppCampaignStatus.CANCELED;
            break;
          case 'PAUSED':
            updatedCampaigns[campaignIndex].status = AppCampaignStatus.PAUSED;
            break;
          case 'RESUMED':
            updatedCampaigns[campaignIndex].status = AppCampaignStatus.IN_PROGRESS;
            break;
          default:
            break;
        }

        this.campaignsSubject$.next(updatedCampaigns);
      });
  }

  public setStateToInitial(): void {
    this.areCampaignsLoadingSubject$.next(false);
    this.campaignsSubject$.next([]);
    this.pageNumberSubject$.next(0);
    this.campaignStatusFilterSubject$.next(-1);
    this.searchKeywordSubject$.next('');
    this.totalCampaignsSubject$.next(0);
    this._linkedInAccountSelectionService.changeSelectedAccounts([]);
    this.selectedCampaignsSubject$.next([]);
    this.storedCampaignsSubject$.next([]);
    this.selectedLinkedInAccountIds = [];

    this.destroy$.next();
    this.destroy$.complete();

    this.destroy$ = new Subject<void>();

    this.initializeService();
  }

  public changeCampaignStatusFilter(campaignStatusFilter: number) {
    this.campaignStatusFilterSubject$.next(campaignStatusFilter);
  }

  public changeSearchKeyword(searchKeyword: string) {
    this.searchKeywordSubject$.next(searchKeyword);
  }

  public changeSelectedCampaigns(campaignIds: number[]) {
    this.selectedCampaignsSubject$.next(campaignIds);
  }

  public changePageNumber(value: number): void {
    if (this.areCampaignsLoadingSubject$.value) {
      return;
    }

    this.pageNumberSubject$.next(value);
  }

  private getCampaignsWhenSelectedFilterActive(): void {
    // dev note: currently, only the filters relevant for the dashboard are considered here
    const storedCampaignIds = this.storedCampaignsSubject$.value.map((campaign) => campaign.id);
    const missingCampaignIds = this.selectedCampaignsSubject$.value.filter(
      (campaignId) => !storedCampaignIds.includes(campaignId),
    );

    // dev note: this is guaranteed to be one campaign in the current state of the system
    if (missingCampaignIds.length == 0) {
      this.getCampaignsFromMemory();
    } else {
      // Use forkJoin to fetch all missing campaigns in parallel
      const campaignRequests = missingCampaignIds.map((missingCampaignId) =>
        this._campaignService.get(missingCampaignId),
      );

      forkJoin(campaignRequests).subscribe((res) => {
        this.storedCampaignsSubject$.next([...this.storedCampaignsSubject$.value, ...res]);
        this.getCampaignsFromMemory();
      });
    }
  }

  // dev note: this function only takes the current dashboard filters into consideration
  private getCampaignsFromMemory() {
    const selectedCampaignIds = this.selectedCampaignsSubject$.value;
    const selectedAccountIds = this.selectedLinkedInAccountIds;

    let filteredCampaigns = this.storedCampaignsSubject$.value.filter((campaign) =>
      selectedCampaignIds.includes(campaign.id),
    );

    if (selectedAccountIds.length > 0) {
      filteredCampaigns = filteredCampaigns.filter((campaign) =>
        campaign.campaignAccounts.some((account) =>
          selectedAccountIds.includes(account.linkedInAccountId),
        ),
      );
    }

    this.campaignsSubject$.next(filteredCampaigns);
    this.totalCampaignsSubject$.next(filteredCampaigns.length);
  }
}
