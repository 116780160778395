<div class="mb-4 border-b border-gray-200 dark:border-gray-700" [ngClass]="'mt-' + marginTop">
  <ul class="flex mx-6 flex-wrap -mb-px text-center" style="height: 47.5px">
    <ng-container *ngFor="let tab of tabs; let i = index">
      <li
        class="font-semibold text-md tracking-wide flex items-center cursor-pointer px-2 py-2 mr-6 border-b-2 border-transparent select-none"
        [ngClass]="{ 'hover:text-primary': !disabled }"
        [class.border-primary]="i === selectedTabIndex"
        [class.text-primary]="i === selectedTabIndex"
        [class.text-secondary]="i !== selectedTabIndex"
        [class.disabled]="additionalData === 0 && tab.name === 'Unread'"
        (click)="selectTab(i)">
        <mat-icon *ngIf="tab.icon" [svgIcon]="tab.icon" class="icon-size-4 mr-4"></mat-icon>
        {{ tab.name }}
        <ng-container *ngIf="tab.name === 'Unread'">
          <span
            *ngIf="additionalData"
            class="text-white bg-primary rounded-full text-xs px-1 py-0.5 inline-flex items-center justify-center ml-1"
            style="width: 2.2em; height: 2.2em">
            {{ additionalData > 99 ? '99+' : additionalData }}
          </span>
        </ng-container>
      </li>
    </ng-container>
  </ul>
</div>
