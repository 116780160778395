import { Injectable } from '@angular/core';
import {
  ApplicationInfoDto,
  GetCurrentLoginInformationsOutput,
  SessionServiceProxy,
  SubscriptionDto,
  TenantLoginInfoDto,
  UserLoginInfoDto,
} from '@shared/service-proxies/service-proxies';
import Gleap from 'gleap';

declare var dataLayer: any;
declare global {
  interface Window {
    birdeatsbug: any;
    intercomSettings: any;
    Intercom: any;
    usetifulTags: any;
  }
}

@Injectable()
export class AppSessionService {
  private _user: UserLoginInfoDto;
  private _subscription: SubscriptionDto;
  private _tenant: TenantLoginInfoDto;
  private _application: ApplicationInfoDto;
  private _usedPromoCode: boolean;

  constructor(private _sessionService: SessionServiceProxy) {}

  get user(): UserLoginInfoDto {
    return this._user;
  }

  get subscription(): SubscriptionDto {
    return this._subscription;
  }

  get tenant(): TenantLoginInfoDto {
    return this._tenant;
  }

  get application(): ApplicationInfoDto {
    return this._application;
  }

  get usedPromoCode(): boolean {
    return this._usedPromoCode;
  }

  set usedPromoCode(value: boolean) {
    this._usedPromoCode = value;
  }

  get userId(): number {
    return this.user ? this.user.id : null;
  }

  get tenantId(): number {
    return this.tenant ? this.tenant.id : null;
  }

  init(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this._sessionService
        .getCurrentLoginInformations()
        .toPromise()
        .then(
          (result: GetCurrentLoginInformationsOutput) => {
            this._application = result.application;
            this._user = result.user;
            this._tenant = result.tenant;
            this._subscription = result.subscription;
            this._usedPromoCode = result.usedPromoCode;
            if (this._user) {
              dataLayer.push({
                event: 'login',
                user_id: this._user.id.toString(),
              });

              // Intercom data
              try {
                if (Gleap) {
                  Gleap.identify(`${this._tenant.id}`, {
                    name: this._user.name,
                    email: this._user.emailAddress,
                    plan: this._subscription?.subscriptionType?.displayName,
                    customData: {
                      isFreeTrial: this._subscription?.isTrial,
                      isSubscribed: this._subscription != null && !this._subscription?.isTrial,
                      isAppSumo: this._subscription?.appSumo,
                      hastActiveSubscription: this._subscription != null,
                      freeTrialStartDate: this._tenant.startedFreeTrialDate,
                      tenantId: this._tenant.id,
                    },
                  });

                  // window.intercomSettings.name = this._user.name;
                  // window.intercomSettings.email = this._user.emailAddress;
                  // window.intercomSettings['Tenant Name'] = this._tenant.name;
                  // window.intercomSettings['UserId'] = this._user.id;
                  // window.intercomSettings['TenantId'] = this._tenant.id;
                  // window.intercomSettings['IsFreeTrial'] = this._subscription?.isTrial;
                  // window.intercomSettings['SubscriptionType'] =
                  //   this._subscription?.subscriptionType?.displayName;
                  // window.intercomSettings['IsSubscribed'] =
                  //   this._subscription != null && !this._subscription?.isTrial;
                  // window.intercomSettings['AppSumo'] = this._subscription?.appSumo;
                  // window.intercomSettings['HasActiveSubscription'] = this._subscription != null;
                  // window.intercomSettings['FreeTrialStartDate'] = new Date(
                  //   this._tenant.startedFreeTrialDate,
                  // ).toISOString();

                  // window.Intercom('update');
                }
              } catch (error) {
                console.error(error);
              }

              // Bird eats bug data
              try {
                if (window?.birdeatsbug) {
                  window.birdeatsbug.setOptions({
                    user: {
                      id: this._user.id,
                      email: this._user.emailAddress,

                      // Optional properties
                      fullName: this._user.name,
                      tenantId: this._tenant.id,
                      tenant: this._tenant.name,
                    },
                  });
                }
              } catch (error) {
                console.log('BirdEatsBug not loaded correctly');
                console.error(error);
              }

              try {
                window.usetifulTags = {
                  userId: this.user.id,
                  emailAddress: this.user.emailAddress,
                  name: this.user.name,
                };
              } catch (error) {
                console.log('Usetiful not loaded correctly');
                console.error(error);
              }
            }

            resolve(true);
          },
          (err) => {
            reject(err);
          },
        );
    });
  }
}
