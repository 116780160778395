<div class="flex flex-auto items-center h-11 rounded-lg border-2 border-pale p-2 px-4">
  <mat-icon class="icon-size-4 text-secondary" svgIcon="heroicons_solid:default-search"></mat-icon>
  <input
    [attr.type]="type ? type : 'text'"
    [attr.id]="id ? type : null"
    type="text"
    #searchField
    [placeholder]="placeholder"
    class="form-control text-base w-full border-none text-dark font-medium"
    [class.form-control-sm]="isSmall"
    [attr.aria-label]="ariaLabel"
    [formControl]="control"
    data-cy="search-field__input"
    [attr.disabled]="disabled ? disabled : null" />
</div>
