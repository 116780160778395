import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'tab-group',
  templateUrl: './tab-group.component.html',
  styles: [
    `
      :host {
        .disabled {
          opacity: 0.5;
          cursor: not-allowed !important;
        }
      }
    `,
  ],
})
export class TabGroupComponent implements OnChanges {
  @Input() tabs: CustomTab[] = [];
  @Input() disabled: boolean = false;
  @Input() selectedTabIndex: number = 0;
  @Input() marginTop: number = 2;
  @Output() selectedTabIndexChange = new EventEmitter<number>();
  @Input() selectedTab: CustomTab = undefined;
  @Input() additionalData: any = undefined;
  @Output() selectedTabChange = new EventEmitter<any>();

  selectTab(index: number): void {
    if (this.additionalData === 0) {
      return;
    }
    if (this.disabled) {
      return;
    }
    this.selectedTabIndex = index;
    this.selectedTabIndexChange.emit(index);

    if (index < this.tabs.length) {
      this.selectedTab = this.tabs[index];
      this.selectedTabChange.emit(this.selectedTab);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedTabIndex && changes.selectedTabIndex.previousValue) {
      if (this.selectedTabIndex > 0 && this.selectedTabIndex < this.tabs.length) {
        this.selectedTab = this.tabs[this.selectedTabIndex];
      }
      this.selectedTabChange.emit(this.selectedTab);
    }
    if (changes.selectedTab && changes.selectedTab.previousValue) {
      this.selectedTabIndex = this.tabs.findIndex((tab) => tab.value === this.selectedTab.value);
      if (this.selectedTabIndex < 0) {
        this.selectedTabIndex = 0;
      }
      this.selectedTabIndexChange.emit(this.selectedTabIndex);
    }
  }
}

export interface CustomTab {
  name: string;
  icon: string;
  value: any;
  notification?: number;
}
