<!-- <p *ngIf="hasTitle">Selected account</p> -->
<mat-form-field class="w-full">
  <img
    *ngIf="defaultAccountImage"
    class="w-6 mr-3 icon-size-5"
    src="assets/images/avatars/profile-circle.svg" />
  <mat-select
    appearance="outline"
    [placeholder]="placeholderLabel"
    #singleSelect
    (selectionChange)="onSelectedAccountChange($event)"
    [multiple]="multiple"
    [ngModel]="multiple ? selectedAccounts : selectedAccounts?.[0]"
    [disabled]="disabled || accounts?.length == 0">
    <mat-option>
      <ngx-mat-select-search
        ngModel
        (ngModelChange)="searchTerm$.next($event)"
        closeSvgIcon="close"
        placeholderLabel="Search"
        noEntriesFoundLabel="No results found."></ngx-mat-select-search>
    </mat-option>
    <mat-option *ngIf="allowNull" [value]="null">
      {{ nullOptionText }}
    </mat-option>
    <mat-option *ngFor="let account of filteredAccounts | async" [value]="account">
      <div class="flex">
        <div class="flex gap-2">
          <user-image-name
            [profile]="account.linkedInUserProfile"
            [altName]="account.emailAddress"
            [showSubscription]="false"></user-image-name>
          <div class="flex items-center justify-center">
            <linkedin-account-subscriptions [account]="account"></linkedin-account-subscriptions>
          </div>
        </div>
        <span class="relative top-1.5 h-5">
          <linkedin-account-search-locked-indicator
            [account]="account"></linkedin-account-search-locked-indicator>
        </span>
      </div>
    </mat-option>
  </mat-select>
</mat-form-field>
