import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import * as Papa from 'papaparse';
import {
  CustomUserFieldDto,
  ImportProfileDto,
  LinkedInUserListDto,
  LinkedInUserListServiceProxy,
} from '@shared/service-proxies/service-proxies';
import { AppListType } from '@shared/AppEnums';
import { FormControl } from '@angular/forms';
import { concatMap, debounceTime, distinctUntilChanged, finalize, takeUntil } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { ModularDialogComponent } from '../dialogs/modular-dialog/modular-dialog.component';
import { ModularDialogData } from '../dialogs/modular-dialog/modular-dialog-interfaces';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as XLSX from 'xlsx';
import { WhiteLabelService } from '@app/white-label.service';
import { Router } from '@angular/router';

@Component({
  selector: 'import-csv',
  templateUrl: './import-csv.component.html',
})
export class ImportCSVComponent implements OnInit {
  loading: boolean = false;
  isFileTooLarge: boolean = false;
  readonly rowsLimit = 60000;

  fields: string[] = [];

  fieldMappingKeywords = {
    profileUrl: ['linkedinurl', 'linkedin url', 'linked in url', 'in url', 'url'],
    firstName: ['first name', 'firstname', 'first', 'name'],
    lastName: ['last name', 'lastname', 'last'],
    location: ['location'],
    summary: ['summary', 'description', 'headline'],
    companyName: ['companyname', 'company', 'company name'],
    position: ['position', 'title'],
    about: ['about'],
    emailAddress: ['email', 'emailaddress', 'email address'],
  };
  fieldMapping: any = {
    profileUrl: '',
    firstName: '',
    lastName: '',
    location: '',
    summary: '',
    companyName: '',
    position: '',
    about: '',
    emailAddress: '',
  };

  leadsToImport: number = 0;
  fileName: string = '';

  lists: LinkedInUserListDto[] = [];
  @Input() listId: number;
  importData: any[];
  hiddenListSelection: boolean;
  pageNumber: number = 0;
  searchString = '';
  listNameControl: FormControl = new FormControl('');
  customFieldRegExp = new RegExp(/^[a-zA-Z0-9_]+$/);
  @Output() onImport: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('csvInput') csvInput: ElementRef;
  prospectsImported: number = 0;
  batchingProgress: number = 0;
  customUserFieldNames: CustomUserFieldMappingInfo[] = [];
  companyName = this.whitelabelService.companyName;
  private destroy$: Subject<void> = new Subject<void>();
  private batchSize = 500;

  constructor(
    private _toaster: MatSnackBar,
    private _dialog: MatDialog,
    private _listService: LinkedInUserListServiceProxy,
    private whitelabelService: WhiteLabelService,
    private _router: Router,
  ) {}

  get selectedList(): LinkedInUserListDto {
    return this.lists.find((x) => x.id == this.listId);
  }

  get hasProfileURL(): boolean {
    return (
      !!this.fieldMapping.profileUrl &&
      !!this.fieldMapping.firstName &&
      !!this.fieldMapping.lastName
    );
  }

  public get isLoading(): boolean {
    return this.loading;
  }

  getAvailableFieldNames(currFieldName: string): string[] {
    const selectedNamesWithoutCurrent = this.customUserFieldNames
      .map((x) => x.nameInSpreadsheet)
      .filter((x) => x != currFieldName);

    return this.fields.filter(
      (x) =>
        !Object.values(this.fieldMapping).includes(x) && !selectedNamesWithoutCurrent.includes(x),
    );
  }

  ngOnInit(): void {
    this.getLists(true);
    this.setFiltersEvents();
  }

  getLists(firstTime: boolean = false): void {
    if (firstTime && this.listId) {
      this.hiddenListSelection = true;

      this._listService.get(this.listId).subscribe((z) => {
        this.lists.push(z);
      });

      return;
    }

    this._listService
      .getPaginatedLists(this.pageNumber, this.searchString, AppListType.USER_LIST, undefined)
      .subscribe((res) => {
        this.lists = this.lists.concat(res.items);
      });
  }

  setFiltersEvents() {
    this.listNameControl.valueChanges
      .pipe(distinctUntilChanged(), debounceTime(500), takeUntil(this.destroy$))
      .subscribe(() => {
        this.lists = [];
        this.searchString = this.listNameControl.value;
        this.pageNumber = 0;
        this.getLists();
      });
  }

  onScrolledFilterList() {
    this.pageNumber++;
    this.getLists();
  }

  onUploadFromCsvClick(): void {
    this.csvInput.nativeElement.click();
  }

  onUploadFileChange(event: any): void {
    const file = event?.target?.files[0];

    if (!file || !file.type || !file.name) {
      this._toaster.open('Unable to open file', undefined, {
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        duration: 3500,
      });
    }

    let pasringSuccess: boolean;
    if (file.type == 'text/csv') {
      pasringSuccess = this.parseCsvFile(file);

      if (pasringSuccess) {
        this.fileName = file.name;
      }
    } else {
      this.parseExcelFile(file).then((success) => {
        if (success) {
          this.fileName = file.name;
        }
      });
    }
    this.csvInput.nativeElement.value = null;
  }

  parseCsvFile(file: any): boolean {
    try {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: async (result) => {
          this.fields = result.meta.fields;
          this.importData = result.data;
          this.leadsToImport = result.data.length;
          this.automapFields();
        },
      });

      return true;
    } catch (error) {
      this._toaster.open('Unable to parse data from file!', undefined, {
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        duration: 4500,
      });
      console.error(error); // leave this console log so we can debug if a client has issues parsing file
      this.clearForm(); // do not show invalid / old data if parsing fails
      return false;
    }
  }

  parseExcelFile(file): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const reader: FileReader = new FileReader();
      let success: boolean = false;

      reader.onload = (e: any) => {
        try {
          const data: string = e.target.result;
          const workbook: XLSX.WorkBook = XLSX.read(data, { type: 'binary' });

          const sheetName: string = workbook.SheetNames[0];
          const worksheet: XLSX.WorkSheet = workbook.Sheets[sheetName];

          const headerRange: XLSX.Range = XLSX.utils.decode_range(worksheet['!ref']);
          const headerRow: any[] = [];
          for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
            const cellAddress = { r: headerRange.s.r, c: col };
            const headerCell = XLSX.utils.encode_cell(cellAddress);
            headerRow.push(worksheet[headerCell]?.v);
          }

          this.fields = headerRow.filter(Boolean);

          const jsonData: any[] = XLSX.utils.sheet_to_json(worksheet, { header: this.fields });

          this.importData = jsonData.slice(1);
          this.leadsToImport = this.importData.length;
          this.automapFields();
          success = true;
        } catch (error) {
          this._toaster.open('Unable to parse data from file!', undefined, {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 4500,
          });
          console.error(error); // leave this console log so we can debug if a client has issues parsing file
          this.clearForm(); // do not show invalid / old data if parsing fails
          resolve(false);
        }
      };
      reader.readAsBinaryString(file);
      resolve(true);
    });
  }

  automapFields(): void {
    for (const key in this.fieldMappingKeywords) {
      if (Object.prototype.hasOwnProperty.call(this.fieldMappingKeywords, key)) {
        const value: string[] = this.fieldMappingKeywords[key];

        let mapped = false;
        for (const map of value) {
          let index = this.fields.findIndex((x) => x.toLowerCase() == map);
          if (index != -1) {
            this.fieldMapping[key] = this.fields[index];
            mapped = true;
            break;
          }
        }

        if (!mapped) {
          for (const map of value) {
            let index = this.fields.findIndex((x) => x.toLowerCase().includes(map));
            if (index != -1) {
              this.fieldMapping[key] = this.fields[index];
              break;
            }
          }
        }
      }
    }
  }

  createNewList(): void {
    this._dialog.open(ModularDialogComponent, {
      data: {
        autoFocus: false,
        disableClose: true,
        callbackFn: async (input: string) => {
          let success: boolean = false;
          await this._listService
            .createList(input, AppListType.USER_LIST)
            .toPromise()
            .then((res) => {
              this.lists.unshift(res);
              this._toaster.open('Successfully created new list!', undefined, {
                horizontalPosition: 'center',
                verticalPosition: 'bottom',
                duration: 3000,
              });
              this.listId = res.id;
              success = true;
              localStorage.setItem('linkedInUserListsFilterClear', JSON.stringify(1));
            });
          return success;
        },
        title: 'Create New List',
        inputLabel: 'List Name',
      } as ModularDialogData,
    });
  }

  import(): void {
    this.loading = true;

    this.customUserFieldNames = this.customUserFieldNames.filter(
      (x) => !Object.values(this.fieldMapping).includes(x.nameInSpreadsheet),
    );

    let data: ImportProfileDto[] = this.importData
      .map(
        (x) =>
          new ImportProfileDto({
            profileUrl: x[this.fieldMapping['profileUrl']],
            firstName: x[this.fieldMapping['firstName']],
            lastName: x[this.fieldMapping['lastName']],
            location: x[this.fieldMapping['location']],
            summary: x[this.fieldMapping['summary']],
            companyName: x[this.fieldMapping['companyName']],
            position: x[this.fieldMapping['position']],
            about: x[this.fieldMapping['about']],
            emailAddress: x[this.fieldMapping['emailAddress']],
            customUserFields: this.customUserFieldNames.map((y) => {
              return new CustomUserFieldDto({
                name: y.mappedName,
                value: x[y.nameInSpreadsheet],
              });
            }),
          } as ImportProfileDto),
      )
      .filter((x) => x.profileUrl != null && x.profileUrl.length > 0);

    this.sendDataInBatches(data)
      .pipe(
        finalize(() => {
          this.loading = false;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this._toaster.open(
          `Successfully imported ${this.prospectsImported}/${this.leadsToImport} leads to the list ${this.selectedList?.name}.`,
          undefined,
          {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 8000,
          },
        );
        this.clearForm();
        this.onImport.emit();
        if (this.listId) {
          this._router.navigate([`app/my-list/${this.listId}`], {
            queryParams: { listType: AppListType.USER_LIST },
          });
        }
      });
  }

  sendDataInBatches(data: ImportProfileDto[]): Observable<any> {
    const numberOfBatches = Math.ceil(data.length / this.batchSize);
    this.prospectsImported = 0;

    let batches: ImportProfileDto[][] = [];

    for (let i = 0; i < numberOfBatches; ++i) {
      const start = i * this.batchSize;
      const end = Math.min((i + 1) * this.batchSize, data.length);
      const batch = data.slice(start, end);
      batches.push(batch);
    }

    const batchesObservable = of(...batches);

    const importBatch = (batch: any) => {
      return this._listService.importUsersToList(this.listId, batch);
    };

    let currentBatch: number = 0;

    const resultObservable = new Observable((observer) => {
      batchesObservable
        .pipe(
          concatMap((batch) => importBatch(batch)),
          finalize(() => {
            observer.next(null);
            observer.complete();
          }),
        )
        .subscribe((res) => {
          currentBatch++;
          if (currentBatch) {
            this.batchingProgress = currentBatch / numberOfBatches;
          }
          this.prospectsImported += res;
        });
    });

    return resultObservable;
  }

  onAddCustomFieldClick(): void {
    this.customUserFieldNames.push({ nameInSpreadsheet: '', mappedName: '' });
  }

  onRemoveCustomFieldClick(i: number): void {
    this.customUserFieldNames.splice(i, 1);
  }

  isImportButtonDisabled(): boolean {
    return (
      this.loading ||
      !this.listId ||
      !this.leadsToImport ||
      !this.hasProfileURL ||
      this.customUserFieldNames.filter(
        (x) => !x.mappedName || !x.nameInSpreadsheet || !this.customFieldRegExp.test(x.mappedName),
      ).length > 0 ||
      this.leadsToImport > this.rowsLimit
    );
  }

  getImportButtonTooltip(): string {
    if (this.loading) {
      return 'Please wait while the list is finished importing.';
    }
    if (!this.listId) {
      return 'Please select a list in which you want to import.';
    }
    if (!this.leadsToImport) {
      return 'There are no leads to import.';
    }
    if (!this.hasProfileURL) {
      return 'Please make sure all of the required mapping columns are selected.';
    }
    if (this.customUserFieldNames.filter((x) => !x.mappedName || !x.nameInSpreadsheet).length > 0) {
      return 'Please make sure that all of the information for the custom fields in entered.';
    }
    if (
      this.customUserFieldNames.length > 0 &&
      this.customUserFieldNames.filter((x) => !this.customFieldRegExp.test(x.mappedName)).length > 0
    ) {
      return 'Please make sure that the custom variable name consists only of letters, numbers and underscores.';
    }
    if (this.leadsToImport && this.leadsToImport > this.rowsLimit) {
      return `You can import up to ${this.rowsLimit} leads at a time. You are currently trying to upload ${this.leadsToImport} leads.`;
    }
    return '';
  }

  mappedFieldNameHasError(val: string): boolean {
    if (!this.customFieldRegExp.test(val)) {
      return true;
    }
  }

  clearForm(): void {
    this.fileName = '';
    this.importData = [];
    this.leadsToImport = 0;
    this.fields = [];
    this.batchingProgress = 0;
  }
}

export interface CustomUserFieldMappingInfo {
  nameInSpreadsheet: string;
  mappedName: string;
}
