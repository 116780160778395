import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  ConversationLinkedInProfileIdDto,
  CrmInfoDto,
  ExportCrmContactsDto,
  ExportCrmConversationsDto,
  ExportCrmInboxContactsDto,
  ExportCrmListContactsDto,
  ExportCrmMyNetworkContactsDto,
  IExportCrmConversationsDto,
  CrmExportLeadProperties,
  CrmLabelDto,
  CrmOwnerDto,
  CrmLeadStatusDto,
} from '@shared/service-proxies/service-proxies';
import { IntegrationsShared } from '@app/integrations/crm-integrations/integrations.shared';
import { finalize } from 'rxjs/operators';
import { NotifyService } from 'abp-ng2-module';
import { InboxStateService } from '@app/inbox/inbox-state.service';

@Component({
  selector: 'export-to-crm',
  templateUrl: './export-to-crm.component.html',
})
export class ExportToCrmComponent extends IntegrationsShared implements OnInit {
  crmIntegrations: CrmInfoDto[];
  crmIntegrationId: number;
  leadStages: CrmLabelDto[];
  leadOwners: CrmOwnerDto[];
  leadStatuses: CrmLeadStatusDto[];
  // leadStage: string;
  exportProperties: CrmExportLeadProperties = new CrmExportLeadProperties();

  loading: boolean = false;

  exportType: CrmExportType;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      conversationAndMemberIds?: ConversationLinkedInProfileIdDto[];
      linkedInProfileMemberIds?: string[];
      myNetworkExport?: ExportCrmMyNetworkContactsDto;
      listExport?: ExportCrmListContactsDto;
      inboxExport?: ExportCrmInboxContactsDto;
      exportType: CrmExportType;
    },
    private injector: Injector,
    private inboxStateService: InboxStateService,
    public dialogRef: MatDialogRef<ExportToCrmComponent>,
    private notify: NotifyService,
  ) {
    super(injector);
    this.exportType = data.exportType;
  }

  ngOnInit(): void {
    this.getAllCrms();
  }

  onSelectedCrmChange() {
    this.getDropdownDataForSelectedCrm();
  }

  getDropdownDataForSelectedCrm(): void {
    this.loading = true;
    this._IntegrationsService
      .getContactExportProperties(this.crmIntegrationId)
      .pipe(
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe(
        (res) => {
          this.leadStages = res.labels;
          this.leadOwners = res.owners;
          this.leadStatuses = res.status;
        },
        (err) => {
          this.getAllCrms();
          this.resetExportParams();
        },
      );
  }

  private resetExportParams(): void {
    this.crmIntegrationId = undefined;
    this.leadStages = [];
    this.leadOwners = [];
    this.leadStatuses = [];
    this.exportProperties = {
      contactLabel: null,
      contactOwner: null,
      contactStatus: null,
    } as CrmExportLeadProperties;
  }

  getAllCrms(): void {
    this.loading = true;
    this._IntegrationsService
      .getAll(undefined, undefined)
      .pipe(
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe((res) => {
        this.crmIntegrations = res.filter((x) => x.authIsValid);
      });
  }

  exportToCrm(): void {
    if (!this.exportType) {
      return;
    }

    switch (this.exportType) {
      case 'CONTACTS':
        this.exportContactsToCrm();
        break;
      case 'CONVERSATIONS':
        this.exportConversationsToCrm();
        break;
      case 'MY_NETWORK':
        this.exportMyNetworkContactsToCrm();
        break;
      case 'LIST':
        this.exportListContactsToCrm();
        break;
      case 'INBOX':
        this.exportInboxContactsToCrm();
        break;
    }
  }

  onSuccessfulExport(): void {
    this.dialogRef.close(true);
    this.notify.success('Successfully exported to CRM!');
  }

  exportConversationsToCrm() {
    this.loading = true;

    this._IntegrationsService
      .exportConversations(
        new ExportCrmConversationsDto({
          crmInfoId: this.crmIntegrationId,
          exportLeadProperties: this.exportProperties,
          conversationAndMemberIds: this.data.conversationAndMemberIds,
        } as IExportCrmConversationsDto),
      )
      .pipe(
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe(() => {
        this.onSuccessfulExport();
      });
  }

  exportContactsToCrm(): void {
    this.loading = true;

    this._IntegrationsService
      .exportContacts(
        new ExportCrmContactsDto({
          crmInfoId: this.crmIntegrationId,
          exportLeadProperties: this.exportProperties,
          linkedInProfileMemberIds: this.data.linkedInProfileMemberIds,
        }),
      )
      .pipe(
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe(() => {
        this.onSuccessfulExport();
      });
  }

  exportMyNetworkContactsToCrm(): void {
    this.loading = true;

    this.data.myNetworkExport.crmInfoId = this.crmIntegrationId;
    this.data.myNetworkExport.exportLeadProperties = this.exportProperties;

    this._IntegrationsService
      .exportMyNetworkContacts(this.data.myNetworkExport)
      .pipe(
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe(() => {
        this.onSuccessfulExport();
      });
  }

  exportListContactsToCrm(): void {
    this.loading = true;

    this.data.listExport.crmInfoId = this.crmIntegrationId;
    this.data.listExport.exportLeadProperties = this.exportProperties;

    this._IntegrationsService
      .exportListContacts(this.data.listExport)
      .pipe(
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe(() => {
        this.onSuccessfulExport();
      });
  }

  exportInboxContactsToCrm(): void {
    this.loading = true;

    this.data.inboxExport.crmInfoId = this.crmIntegrationId;
    this.data.inboxExport.exportLeadProperties = this.exportProperties;
    this.data.inboxExport.excludeProfileIds = this.inboxStateService.getExcludedProfileIds();
    this._IntegrationsService
      .exportInboxContacts(this.data.inboxExport)
      .pipe(
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe(() => {
        this.onSuccessfulExport();
      });
  }

  get isCrmButtonDisabled(): boolean {
    return this.crmIntegrationId == null || this.loading;
  }
}

export type CrmExportType = 'CONVERSATIONS' | 'CONTACTS' | 'LIST' | 'MY_NETWORK' | 'INBOX';
