import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AddonFeaturesService } from '@shared/email-credits/email-credits.service';
import { WARN_MESSAGE } from '@shared/consts/messages';
import {
  EmailEnrichmentServiceProxy,
  LinkedInProfileEmailEnrichmentDto,
  LinkedInUserProfileDto,
  UpdateLinkedInProfileEmailDto,
} from '@shared/service-proxies/service-proxies';
import { ConfirmationDialogComponent } from '@shared/components/dialogs/confirmation-dialog.component';
import { finalize } from 'rxjs/operators';

import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'email-enrich-button',
  templateUrl: './email-enrich-button.component.html',
  styleUrls: ['./email-enrich-button.component.scss'],
})
export class EmailEnrichButtonComponent implements OnChanges {
  @Input() profile: LinkedInUserProfileDto;
  @Input() enrichingInProgress: boolean = false;
  @Input() showAsButton: boolean = true;
  @Input() disableEmailTooltip: boolean = false;
  @Input() enableEmailEditing: boolean = false;

  isEmailEditMode: boolean = false;
  newEmail: string = '';
  isUpdateEmailInProgress: boolean = false;

  constructor(
    private _addonFeatures: AddonFeaturesService,
    private _emailEnrichmentService: EmailEnrichmentServiceProxy,
    private _toaster: MatSnackBar,
    private _changeDetectorRef: ChangeDetectorRef,
    private _dialog: MatDialog,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.profile) {
      this.isEmailEditMode = false;
      this.newEmail = '';
    }
  }

  get userEmail(): string {
    return this.profile?.emailEnrichment?.emailAddress;
  }

  get emailEnrichmentAtempted(): boolean {
    return !!this.profile?.emailEnrichment;
  }

  enrichEmail() {
    if (this._addonFeatures.emailCredits == 0) {
      const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
        data: {
          message: WARN_MESSAGE,
          description:
            "You don't have any credits to enrich the email, you can purchase additional credits to proceed",
          showButtons: false,
        },
      });
    } else {
      const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
        data: {
          message: 'Are you sure ?',
          description: 'This action will cost you 1 email credit',
          confirmButtonColor: 'red',
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.enrichingInProgress = true;
          this._addonFeatures
            .enrichEmailProfiles([this.profile.id])
            .then((res) => {
              this.profile = res.find((x) => x.id == this.profile.id);
              this._changeDetectorRef.detectChanges();
            })
            .finally(() => {
              this.enrichingInProgress = false;
            });
        }
      });
    }
  }

  setEmailEditMode(value: boolean): void {
    this.isEmailEditMode = value;
    if (!value) {
      this.newEmail = '';
    }
  }

  get isEmailButtonDisabled(): boolean {
    return !this.newEmail || this.isUpdateEmailInProgress;
  }

  updateEmail(): void {
    this.isUpdateEmailInProgress = true;

    const input = {
      emailAddress: this.newEmail,
      memberId: this.profile.id,
    } as UpdateLinkedInProfileEmailDto;

    this._emailEnrichmentService
      .updateProfileEmail(input)
      .pipe(
        finalize(() => {
          this.isUpdateEmailInProgress = false;
        }),
      )
      .subscribe(() => {
        this._toaster.open('Successfully updated e-mail address!', undefined, { duration: 4000 });
        if (!this.profile.emailEnrichment)
          this.profile.emailEnrichment = {
            emailAddress: this.newEmail,
          } as LinkedInProfileEmailEnrichmentDto;
        else this.profile.emailEnrichment.emailAddress = this.newEmail;

        this.isEmailEditMode = false;
        this.newEmail = '';
      });
  }
}
