import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { LinkedInUserProfileDto } from '@shared/service-proxies/service-proxies';

@Component({
  selector: 'user-image-name',
  templateUrl: './user-image-name.component.html',
  styleUrls: ['./user-image-name.component.scss'],
})
export class UserImageNameComponent implements OnInit {
  @Input() profile: LinkedInUserProfileDto;
  @Input() altName: string;
  @Input() showSubscription: boolean = true;
  @Input() tiny: boolean = false;

  constructor(public element: ElementRef) {}

  ngOnInit(): void {}
}
