import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
})
export class BannerComponent implements OnChanges {
  @Input() color: 'primary' | 'warn' = 'primary';

  borderColor: string = 'bg-primary-translucent';
  textColor: string = 'text-primary';

  private colorClassMap: Record<'primary' | 'warn', { textColor: string; borderColor: string }> = {
    primary: { textColor: 'text-primary', borderColor: 'bg-primary-translucent' },
    warn: { textColor: 'text-warn', borderColor: 'bg-warn-translucent' },
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.color) {
      this.setAppropriateColors();
    }
  }

  setAppropriateColors(): void {
    const classes = this.colorClassMap[this.color];
    this.textColor = classes.textColor;
    this.borderColor = classes.borderColor;
  }
}
