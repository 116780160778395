<div class="flex flex-col items-center gap-y-4 w-100">
  <h1>Add Leads to Campaign</h1>
  <div class="flex flex-col items-center w-full items-stretch">
    <div class="font-bold mt-2 mb-4">
      You are about to add {{ leadsCount }} lead/s to a campaign
    </div>
    <mat-form-field appearance="outline" class="w-full">
      <mat-select
        [multiple]="false"
        placeholder="Select a campaign"
        msInfiniteScroll
        [(ngModel)]="campaignId"
        (infiniteScroll)="onScrolledFilterCampaign()">
        <mat-option>
          <ngx-mat-select-search
            closeSvgIcon="close"
            placeholderLabel="Search or select campaign"
            noEntriesFoundLabel="No results found."
            [formControl]="campaignNameControl"></ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let campaign of campaigns" [value]="campaign.id">
          <div style="display: flex; align-items: center">
            <span style="flex-grow: 1">{{ campaign.name }}</span>
            <div
              class="ml-2 py-0.5 px-2 inline rounded-full text-sm font-semibold {{
                getStatusColor(campaign.status)
              }} text-center">
              {{ getStatusText(campaign.status) }}
            </div>
          </div>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button
      [disabled]="isAddButtonDisabled"
      heyreachButton
      buttonType="primary"
      (click)="addLeadsToCampaign()">
      <span [loading]="loading">Add to campaign</span>
    </button>
  </div>
</div>
