import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AppLinkedInPremiumPlans } from '@shared/AppEnums';
import { LinkedInUserProfileDto } from '@shared/service-proxies/service-proxies';

@Component({
  imports: [MatIconModule, MatTooltipModule, CommonModule],
  selector: 'linkedin-user-profile-subscriptions',
  templateUrl: './linkedin-user-profile-subscriptions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class LinkedInUserProfileSubscriptionsComponent implements OnInit {
  @Input() profile: LinkedInUserProfileDto;

  shownPlan: 'premium' | 'sales-navigator' | 'recruiter' | 'none';
  showPremium: boolean;
  showNavigator: boolean;
  showRecruiter: boolean;

  ngOnInit(): void {
    this.determineShownPlan();
  }

  private determineShownPlan(): void {
    this.showRecruiter = this.profile?.premiumPlans.includes(AppLinkedInPremiumPlans.RECRUITER);
    this.showNavigator = this.profile?.premiumPlans.includes(
      AppLinkedInPremiumPlans.SALES_NAVIGATOR,
    );
    this.showPremium =
      this.profile?.premiumPlans.includes(AppLinkedInPremiumPlans.PREMIUM) &&
      !this.showRecruiter &&
      !this.showPremium;
  }
}
