<mat-label *ngIf="showLabel && !showSecondLabel" class="font-semibold text-gray-600 tracking-wide">
  Select Campaign:
</mat-label>

<mat-label *ngIf="showSecondLabel" class="font-semibold text-lg text-gray-800 tracking-wide mb-1">
  Campaign
  <span class="block mt-1 text-gray-500 text-sm font-medium my-1">
    Select the campaign you want to send notifications from
  </span>
</mat-label>

<mat-form-field class="w-full">
  <mat-select
    [panelClass]="'min-w-100'"
    [placeholder]="placeholder"
    (selectionChange)="onSelectedCampaignsChange()"
    multiple
    [(ngModel)]="selectedCampaigns"
    msInfiniteScroll
    (infiniteScroll)="onScrolled()"
    [infiniteScrollThrottle]="500"
    class="w-full bg-white">
    <mat-option>
      <ngx-mat-select-search
        closeSvgIcon="none"
        placeholderLabel="Search or select campaign"
        #searchInput
        noEntriesFoundLabel=""
        [(ngModel)]="searchTerm"
        (ngModelChange)="searchTermUpdate.next(searchInput.value)"
        [disableScrollToActiveOnOptionsChanged]="true"
        [alwaysRestoreSelectedOptionsMulti]="false"></ngx-mat-select-search>
      <ng-container *ngIf="selectedCampaigns.length > 0 && !fetchingFromApi">
        <mat-icon
          class="w-8 absolute bottom-4 z-9999 left-70 right-0 cursor-pointer text-secondary"
          (click)="onClearFilters()"
          svgIcon="heroicons_outline:delete-icon"></mat-icon>
      </ng-container>
    </mat-option>

    <mat-option *ngFor="let campaign of selectedCampaigns" [value]="campaign">
      {{ campaign.name }}
    </mat-option>

    <mat-option *ngFor="let campaign of filteredCampaigns" [value]="campaign">
      {{ campaign.name }}
    </mat-option>

    <div *ngIf="!fetchingFromApi && campaigns.length == 0" class="w-10/12 flex justify-start">
      <div class="ml-4 flex text-base">No campaigns found.</div>
    </div>

    <div *ngIf="fetchingFromApi" class="flex justify-center">
      <progress-bar [determinate]="false" class="w-10/12"></progress-bar>
    </div>
  </mat-select>
</mat-form-field>
