<div class="flex flex-col gap-y-4 w-full text-center pt-4">
  <app-banner class="mx-7">
    *Make sure that you have the mandatory fields ("First Name", "Last Name" and "LinkedIn Profile
    URL") populated within the CSV file for all of your data to get imported within
    {{ companyName }}. In case the fields are missing cells in the file, we will still import that
    data by excluding the missing rows.
  </app-banner>
  <ng-container *ngIf="!loading">
    <div class="flex justify-center">
      <button
        (click)="onUploadFromCsvClick()"
        heyreachButton
        buttonType="default"
        matTooltip="Note: the file that you are trying to upload must be less than 30MB.">
        <div class="flex flex-row justify-around items-center px-7">
          <mat-icon svgIcon="upload_file"></mat-icon>
          <span>Upload spreadsheet {{ fileName }} | {{ leadsToImport }} leads to import</span>
        </div>
      </button>
      <input
        #csvInput
        type="file"
        class="hidden"
        (change)="onUploadFileChange($event)"
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
    </div>
    <div *ngIf="fileName && !isFileTooLarge">
      <div class="mb-3 mx-7">
        <import-mapping [CSVFields]="fields" [fieldMapping]="fieldMapping"></import-mapping>

        <!-- Custom fields -->
        <div class="mb-3">
          <ng-container *ngFor="let field of customUserFieldNames; index as i">
            <div class="flex">
              <div class="flex-1 mr-1 mt-auto">
                <mat-form-field class="w-full">
                  <mat-label>Map from</mat-label>
                  <mat-select [(ngModel)]="field.nameInSpreadsheet">
                    <mat-select-trigger>
                      <span>
                        {{ field.nameInSpreadsheet }}
                      </span>
                    </mat-select-trigger>
                    <mat-option
                      *ngFor="let fieldName of getAvailableFieldNames(field.nameInSpreadsheet)"
                      [value]="fieldName">
                      {{ fieldName }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="flex-1 ml-1 mt-auto">
                <mat-form-field class="w-full">
                  <input
                    type="text"
                    matInput
                    [(ngModel)]="field.mappedName"
                    [class.text-red]="
                      field.mappedName && mappedFieldNameHasError(field.mappedName)
                    " />
                </mat-form-field>
              </div>
              <div class="w-1/12 mt-auto mb-auto">
                <button
                  (click)="onRemoveCustomFieldClick(i)"
                  matTooltip="Remove"
                  heyreachButton
                  buttonType="icon">
                  <mat-icon class="text-warn" svgIcon="close"></mat-icon>
                </button>
              </div>
            </div>
            <div *ngIf="field.mappedName && mappedFieldNameHasError(field.mappedName)">
              <mat-error class="text-sm text-left">
                Please make sure that the custom variable name consists only of letters, numbers and
                underscores.
              </mat-error>
            </div>
          </ng-container>
        </div>
        <button
          class="w-full"
          heyreachButton
          buttonType="default"
          (click)="onAddCustomFieldClick()">
          <mat-icon class="icon-size-5 mr-2" svgIcon="heroicons_outline:plus-sm"></mat-icon>
          Add custom variable
        </button>
      </div>

      <div class="flex flex-col w-full px-7">
        <div class="flex mb-4" *ngIf="!hiddenListSelection">
          <mat-form-field class="w-full">
            <mat-label>Select a list to import to</mat-label>
            <mat-select
              [multiple]="false"
              placeholder="Select a list"
              msInfiniteScroll
              [(ngModel)]="listId"
              (infiniteScroll)="onScrolledFilterList()">
              <mat-option>
                <ngx-mat-select-search
                  closeSvgIcon="close"
                  placeholderLabel="Search lists"
                  noEntriesFoundLabel="No results found."
                  [formControl]="listNameControl"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let list of lists" [value]="list.id">
                <span>{{ list.name }}</span>
              </mat-option>
            </mat-select>
          </mat-form-field>
          <button
            heyreachButton
            buttonType="default"
            (click)="createNewList()"
            class="mt-6 ml-2 w-80">
            <img
              ngSrc="assets/icons/sidebar-icons/my-lists_dark.svg"
              class="icon-size-5 ml-2"
              height="24"
              width="24" />
            <span class="px-2">Create empty list</span>
          </button>
        </div>
        <div class="font-bold mt-2 mb-4" *ngIf="listId && leadsToImport && !loading">
          You are about to import
          {{ leadsToImport }}
          lead/s to the "{{ selectedList?.name }}" list
        </div>
        <span [matTooltip]="getImportButtonTooltip()">
          <button
            class="w-full"
            [disabled]="isImportButtonDisabled()"
            heyreachButton
            buttonType="primary"
            (click)="import()">
            <span>Import</span>
          </button>
        </span>
      </div>
    </div>
  </ng-container>
  <div class="mt-2" *ngIf="loading">
    <div class="font-semibold">
      Importing leads, please
      <span class="text-warn">do not close</span>
      this tab while the import is in progress.
    </div>
    <div class="font-semibold text-primary">
      {{ batchingProgress * 100 | number: '2.2-2' }}% done, {{ prospectsImported }} leads imported.
    </div>
  </div>
  <div *ngIf="isFileTooLarge">
    <span class="px-5 text-warn">
      The file you tried to upload is too large. Please upload a file that is less than 30MB.
    </span>
  </div>
</div>
