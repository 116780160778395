import { Injectable, Injector } from '@angular/core';
import { PlatformLocation } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment-timezone';
import { merge as _merge } from 'lodash-es';
import { AppConsts } from '@shared/AppConsts';
import { AppSessionService } from '@shared/session/app-session.service';
import { environment } from './environments/environment';
import { WhiteLabelService } from '@app/white-label.service';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppInitializer {
  constructor(
    private _injector: Injector,
    private _platformLocation: PlatformLocation,
    private _httpClient: HttpClient,
    private whiteLabelService: WhiteLabelService,
  ) {}

  init(): () => Promise<boolean> {
    return () => {
      abp.ui.setBusy();
      return new Promise<boolean>((resolve, reject) => {
        AppConsts.appBaseHref = this.getBaseHref();
        const appBaseUrl = this.getDocumentOrigin() + AppConsts.appBaseHref;
        this.getApplicationConfig(appBaseUrl, () => {
          this.getUserConfiguration(() => {
            abp.event.trigger('abp.dynamicScriptsInitialized');
            // do not use constructor injection for AppSessionService
            const appSessionService = this._injector.get(AppSessionService);
            appSessionService.init().then(
              (result) => {
                abp.ui.clearBusy();

                resolve(result);
              },
              (err) => {
                abp.ui.clearBusy();
                reject(err);
              },
            );
          });
        });
      });
    };
  }

  private getBaseHref(): string {
    const baseUrl = this._platformLocation.getBaseHrefFromDOM();
    if (baseUrl) {
      return baseUrl;
    }

    return '/';
  }

  private getDocumentOrigin(): string {
    if (!document.location.origin) {
      const port = document.location.port ? ':' + document.location.port : '';
      return document.location.protocol + '//' + document.location.hostname + port;
    }

    return document.location.origin;
  }

  private getCurrentClockProvider(currentProviderName: string): abp.timing.IClockProvider {
    if (currentProviderName === 'unspecifiedClockProvider') {
      return abp.timing.unspecifiedClockProvider;
    }

    if (currentProviderName === 'utcClockProvider') {
      return abp.timing.utcClockProvider;
    }

    return abp.timing.localClockProvider;
  }

  private getUserConfiguration(callback: () => void): void {
    const cookieLangValue = abp.utils.getCookieValue('Abp.Localization.CultureName');
    const token = abp.auth.getToken();

    const requestHeaders = {
      'Abp.TenantId': `${abp.multiTenancy.getTenantIdCookie()}`,
      '.AspNetCore.Culture': `c=${cookieLangValue}|uic=${cookieLangValue}`,
    };

    if (token) {
      requestHeaders['Authorization'] = `Bearer ${token}`;
    }

    this._httpClient
      .get<any>(`${AppConsts.remoteServiceBaseUrl}/AbpUserConfiguration/GetAll`, {
        headers: requestHeaders,
      })
      .subscribe((response) => {
        const result = response.result;

        _merge(abp, result);

        abp.clock.provider = this.getCurrentClockProvider(result.clock.provider);

        moment.locale(abp.localization.currentLanguage.name);

        if (abp.clock.provider.supportsMultipleTimezone) {
          moment.tz.setDefault(abp.timing.timeZoneInfo.iana.timeZoneId);
        }

        callback();
      });
  }

  private getApplicationConfig(appRootUrl: string, callback: () => void) {
    this._httpClient
      .get<any>(`${appRootUrl}assets/${environment.appConfig}`, {
        headers: {
          'Abp.TenantId': `${abp.multiTenancy.getTenantIdCookie()}`,
        },
      })
      .pipe(
        tap((response) => {
          AppConsts.appBaseUrl = response.appBaseUrl;
          AppConsts.remoteServiceBaseUrl = response.remoteServiceBaseUrl;
          AppConsts.signalRServiceBaseUrl = response.signalRServiceBaseUrl;
          AppConsts.stripeApiKey = response.stripe.apiKey;
          AppConsts.localeMappings = response.localeMappings;
          AppConsts.extensionId = response.chrome_extension.id;
          AppConsts.extensionDownloadUrl = response.chrome_extension.install_url;
        }),
        switchMap((response) => {
          // const host =
          //   window.location.hostname === 'localhost'
          //     ? 'test.heyreach.run'
          //     : window.location.hostname;
          const host = window.location.hostname;
          return this._httpClient
            .get<any>(
              `${response.remoteServiceBaseUrl}/api/WhiteLabel/WhiteLabelThemeConfig?domain=${host}`,
              {
                headers: {
                  'Abp.TenantId': `${abp.multiTenancy.getTenantIdCookie()}`,
                },
              },
            )
            .pipe(
              catchError((error) => {
                console.error('Error getting application config', error);
                return of(null);
              }),
            );
        }),
      )
      .subscribe((response) => {
        if (response) {
          this.whiteLabelService.setUpConfig(response);
        } else {
          this.whiteLabelService.setUpDefaultConfig();
        }

        callback();
      });
  }
}
