import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InboxStateService } from '@app/inbox/inbox-state.service';
import { accountModuleAnimation } from '@shared/animations/routerTransition';
import { AddLeadsToCampaignComponent } from '@shared/components/dialogs/add-leads-to-campaign/add-leads-to-campaign.component';
import { AddLeadsFromMemberIdsOrInboxComponent } from '@shared/components/dialogs/add-leads-to-inbox/add-leads-from-member-ids-or-inbox-dialog.component';
import {
  CrmExportType,
  ExportToCrmComponent,
} from '@shared/components/dialogs/export-to-crm/export-to-crm.component';
import { InboxService } from '@shared/inbox-service/inbox.service';
import {
  LinkedInUserProfileDto,
  LnkLinkedInProfileTagDto,
  MemberAndAccountIdDto,
} from '@shared/service-proxies/service-proxies';
@Component({
  selector: 'linkedin-profile-display',
  templateUrl: './linkedin-profile-display.component.html',
  animations: [accountModuleAnimation()],
})
export class LinkedinProfileDisplayComponent {
  @Input() profile: LinkedInUserProfileDto;
  @Input() accountId: number;

  isAccountCheckedAndSelected: boolean = false;
  constructor(
    private _inboxService: InboxService,
    private inboxStateService: InboxStateService,
    private _dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.inboxStateService.selectedChats$.subscribe((selectedChats) => {
      this.isAccountCheckedAndSelected = selectedChats.some(
        (c) => c.correspondentMemberId === this.profile.id,
      );
    });
  }
  onAssignTag(tagLnks: LnkLinkedInProfileTagDto[]) {
    // TODO: This is not needed code for unibox we can remove it , check if it's being used from other components
    // THe idea is that we are changing the tags in state service that provides the profile so there is no need to change it locally in the profile here.
    let profileIds = tagLnks.map((x) => x.profileId);
    if (profileIds.includes(this.profile.id)) {
      let tags = tagLnks.filter((z) => z.profileId == this.profile.id);
      // push with loop for reference instead of concat
      for (const tagLnk of tags) {
        if (!this.profile.tagLinks.includes(tagLnk)) {
          this.profile.tagLinks.push(tagLnk);
        }
      }
    }
    //TODO: END OF NOT NEEDED CODE

    const selectedChat = this._inboxService.selectedChatValue();
    if (selectedChat?.correspondentProfile) {
      this.inboxStateService.assignTag(tagLnks);
    }
  }

  // TODO : when a tag is deleted actually remove it from all tag fields
  // possible solution to this is InboxTagComponent to bind on onInboxChange and
  // check if InboxTagService.tags contains all tags from all profiles, if not then
  // delete the tags from all profiles; also do the same when a tag is edited
  onUnassignTag(tagId: number) {
    const selectedChat = this._inboxService.selectedChatValue();
    if (selectedChat?.correspondentProfile) {
      this.inboxStateService.unAssignTag(tagId);
    }
  }

  onExportToCrmClick(): void {
    let exportType: CrmExportType = 'CONTACTS';

    this._dialog.open(ExportToCrmComponent, {
      data: {
        linkedInProfileMemberIds: [this.profile.id],
        exportType: exportType,
      },
    });
  }

  openAddToCampaignDialog(): void {
    if (this.accountId == null) return;

    this._dialog.open(AddLeadsToCampaignComponent, {
      data: {
        leadsToAdd: [
          new MemberAndAccountIdDto({
            linkedInAccountId: this.accountId,
            memberId: this.profile?.id,
          }),
        ],
        exportType: 'CONTACTS',
        leadsCount: 1,
      },
    });
  }

  openAddToListDialog(): void {
    this._dialog.open(AddLeadsFromMemberIdsOrInboxComponent, {
      autoFocus: false,
      disableClose: true,
      data: {
        mode: 'FROM_MEMBER_IDS',
        memberIds: [this.profile?.id],
      },
      width: '700px',
    });
  }
}
